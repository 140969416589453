import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';

/* SERVICES */
import { XUserTokenService } from '../../services/token/xUserToken.service';
import { SessionService } from '../../services/session/session.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

	/**
	 * Creates an instance of ResponseInterceptor.
	 */
	constructor(
		private xUserTokenSer: XUserTokenService,
		private sessionSer: SessionService
	) {}

	/**
	 * This function intercepts HTTP requests and extracts a token from the response body to set it in a
	 * service and start a session process.
	 * @param req - HttpRequest<any> is a generic type that represents an HTTP request. It contains
	 * information such as the request URL, headers, and body.
	 * @param {HttpHandler} next - `next` is an instance of the `HttpHandler` class, which represents the
	 * next interceptor or the backend server that will handle the HTTP request. It is responsible for
	 * forwarding the request to the next interceptor or the backend server and returning the response
	 * back to the previous interceptor.
	 * @returns An Observable of HttpEvent<any> is being returned.
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap((event) => {
				// The token is extracted.
				if((event as any).body){
					const token = (event as any).body.accessToken;
					if(token && req.url.endsWith("/retornapp/trader/auth")){
						this.xUserTokenSer.set(token);
						this.sessionSer.startingProcess();
					}
				}
			}
		));
	}
}