import { Injectable } from '@angular/core';
import { DEFAULT_LIMIT } from '../constants/traders.const';
import { BehaviorSubject, Observable, firstValueFrom, of, map, catchError } from 'rxjs';
import { HttpService } from './http/http.service';
import { AccountInfo } from '@azure/msal-browser';
import { EncodingService } from './encoding';

/* The `export interface AppPermission` is an interface for an object that
represents the permissions of a user for a specific application. It has four optional properties:
`rol`, `applicationId`, `applicationName`, and `permissions`, all of which are strings. */
export interface AppPermission {
  rol?: string,
  applicationId?: string
  applicationName?: string
  permissions?: string[]
}
/* The `export interface UserPermission` is an interface for an object that
represents the general information of a user and the solutions that they have access to. It has
several optional properties such as `avatar`, `displayName`, `firstName`, `middleName`, `lastName`,
`phoneNumber`, and `emailAddress`, which represent the user's personal information. */
export interface UserPermission {
  avatar?: string
  displayName?: string
  firstName?: string
  middleName?: string
  lastName?: string
  phoneNumber?: string
  emailAddress?: string
  app: AppPermission[]
}

/* The above code is defining an interface named "RetornAppUser" . This interface has
several optional properties related to a customer's information such as their name, address, phone
number, email, etc. It also includes properties related to the customer's establishment and extended
attributes. This interface can be used as a type for objects that conform to this structure. */
export interface RetornAppUser {
  Customer_objectId?: string,
  Customer_externalId?: string,
  Customer_displayName?: string,
  Customer_firstName?: string,
  Customer_middleName?: string,
  Customer_lastName?: string,
  Customer_tin?: string,
  Customer_streetAddress?: string,
  Customer_district?: string,
  Customer_city?: string,
  Customer_state?: string,
  Customer_postalCode?: string,
  Customer_phoneNumber?: string,
  Customer_emailAddress?: string,
  Customer_createdAt?: string,
  Customer_bottlerCompanyName?: string,
  Customer_bottlerCompanyNsrId?: string,
  Customer_bottlerCompanyId?: number,
  Customer_bottlerCompanyCountry?: string,
  Customer_clusterLevel?: string,
  Customer_country?: string,
  Customer_customerType?: string,
  CustomerProfile_rol?: string,
  CustomerProfile_traderId?: string,
  CustomerEstablishment_id?: string,
  CustomerEstablishment_name?: string,
  CustomerEstablishment_route?: string,
  CustomerEstablishment_coorX?: string,
  CustomerEstablishment_coorY?: string,
  CustomerEstablishment_gmLink?: string,
  CustomerEstablishment_establishmentTypeName?: string,
  CustomerExtendedAtribute_DateInit?: string,
  CustomerExtendedAtribute_BotellasIniciadas?: string,
  
  CustomerExtendedAtribute_DailyDigitalizeLimit?: string,
  CustomerExtendedAtribute_DailyRedeenLimit?: string,
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    /* holds the logged status from the user in the aplication. */
    public loggedInStatus: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
    
    /* holds the msal account info from the user logged */
    public activeAccount!: AccountInfo;
    /* holds the global user information by CustomerPortal with the access to applications. */
    private userPermissions: BehaviorSubject<UserPermission | null> = new BehaviorSubject<UserPermission | null>(null);
    /* holds the user information by RetornApp. */
    public retornAppUser: BehaviorSubject<RetornAppUser | null> = new BehaviorSubject<RetornAppUser | null>(null);
  
    /**
     * This is a constructor function that takes an HttpService as a parameter and assigns it to a
     * private property.
     */
    constructor(
        private httpService: HttpService,
        private encoding: EncodingService,
    ) {}

    /**
     * Local login
     * @param activeAccount {AccountInfo} msal account info
     */
    public login(activeAccount: AccountInfo | null) {
      if (this.loggedInStatus.getValue() === true || activeAccount === null) return;
      this.activeAccount = activeAccount;
      this.loggedInStatus.next(true);
    }

    /**
     * The function returns an observable that emits a boolean or null value representing the
     * authentication status.
     * @returns The `isAuthenticated()` method is returning an Observable that emits a boolean value or
     * null. The boolean value indicates whether the user is currently authenticated or not, and the
     * null value indicates that the authentication status is unknown or has not been set yet. 
     */
    public isAuthenticated(): Observable<boolean | null> {
      return this.loggedInStatus.asObservable();
    }
    /**
     * Local logout
     */
    public logout(): void {
        this.loggedInStatus.next(false);
    }

    /**
     * Get the url to extend de currect session
     * @returns {Promise<string>} url to extend session
     */
    public async getMagicLink(): Promise<string> {
      const rawData = {
        email: this.userPermissions.getValue()?.emailAddress,
        displayName: this.userPermissions.value?.firstName
      };
      const data = this.encoding.encodeObjectToBase64(rawData);
      try {
        const response = await firstValueFrom(this.httpService.post('/customer/getMagicLynkLogin', { data }, false, true));
        return response?.url;
      } catch (error) {
        return '';
      }
    }

    /**
     * Observable from userPermissions object, result from getUserPermissions
     * @returns {Observable<UserPermission | null>} General user information and the solutions that have access.
     */
    public getUserPermissions(): Observable<UserPermission | null> {
      return this.userPermissions.asObservable();
    }
    
    /**
     * Consume and save the general information from the user and know the solutions that have access.
     * @param activeAccount {AccountInfo} msal account info
     * @returns {Observable<UserPermission | null>} user info and permissions
     */
    public consumeUserPermissions(activeAccount: AccountInfo): Observable<UserPermission | null> {
      // Create the data to send
      const rawData = {
        objectId: activeAccount.localAccountId
      };

      // Encode the data to base64
      const data = this.encoding.encodeObjectToBase64(rawData);

      return this.httpService.post('/cmdp/getUserPermissions', { data }, false, true).pipe(
        map((userPermissions: any) => {
            if(!userPermissions) return null;
            const app: AppPermission[] = userPermissions.map((up: any) => {
              return {
                rol: up?.CustomerProfile_rol,
                applicationId: up?.CustomerProfile_applicationId,
                applicationName: up?.CustomerProfile_applicationName,
                permissions: up?.permissions
              }
            });
            const up: UserPermission = {
              avatar: '',
              displayName: userPermissions[0]?.Customer_displayName ?? '',
              firstName: userPermissions[0]?.Customer_firstName ?? '',
              middleName: userPermissions[0]?.Customer_middleName ?? '',
              lastName: userPermissions[0]?.Customer_lastName ?? '',
              phoneNumber: userPermissions[0]?.Customer_phoneNumber ?? '',
              emailAddress: userPermissions[0]?.Customer_emailAddress ?? '',
              app: app
            }
            this.userPermissions.next(up);
            return up;
        }),
        catchError((err:any) => {
          return of (null);
        })
      );
    }

    /**
     * Consume and save the Retornapp user information
     * @param activeAccount {AccountInfo} msal account info
     * @returns {Observable<UserPermission | null>} user info
     */
    public consumeCustomerByProfile(activeAccount: AccountInfo): Observable<RetornAppUser | null> {
      // Create the data to send
      const rawData = {
        objectId: activeAccount.localAccountId
      };

      // Encode the data to base64
      const data = this.encoding.encodeObjectToBase64(rawData);

      return this.httpService.post('/manage/getCustomerByProfile?clientId=apim-cocacolaAPI-apim', { data }, true, true).pipe(
        map((data: any) => {
          if (!data || data.length === 0) return null;
          const us = data[0];

          const retornAppUser: RetornAppUser = {
            Customer_objectId: us.Customer_objectId,
            Customer_externalId: us.Customer_externalId,
            Customer_displayName: us.Customer_displayName,
            Customer_firstName: us.Customer_firstName,
            Customer_middleName: us.Customer_middleName,
            Customer_lastName: us.Customer_lastName,
            Customer_tin: us.Customer_tin,
            Customer_streetAddress: us.Customer_streetAddress,
            Customer_district: us.Customer_district,
            Customer_city: us.Customer_city,
            Customer_state: us.Customer_state,
            Customer_postalCode: us.Customer_postalCode,
            Customer_phoneNumber: us.Customer_phoneNumber,
            Customer_emailAddress: us.Customer_emailAddress,
            Customer_createdAt: us.Customer_createdAt,
            Customer_bottlerCompanyName: us.Customer_bottlerCompanyName,
            Customer_bottlerCompanyNsrId: us.Customer_bottlerCompanyNsrId,
            Customer_bottlerCompanyId: us.Customer_bottlerCompanyId,
            Customer_bottlerCompanyCountry: us.Customer_bottlerCompanyCountry,
            Customer_clusterLevel: us.Customer_clusterLevel,
            Customer_country: us.Customer_country,
            Customer_customerType: us.Customer_customerType,
            CustomerProfile_rol: us.CustomerProfile_rol,
            CustomerProfile_traderId: us.CustomerProfile_traderId,
            CustomerEstablishment_id: us.CustomerEstablishment_id,
            CustomerEstablishment_name: us.CustomerEstablishment_name,
            CustomerEstablishment_route: us.CustomerEstablishment_route,
            CustomerEstablishment_coorX: us.CustomerEstablishment_coorX,
            CustomerEstablishment_coorY: us.CustomerEstablishment_coorY,
            CustomerEstablishment_gmLink: us.CustomerEstablishment_gmLink,
            CustomerEstablishment_establishmentTypeName: us.CustomerEstablishment_establishmentTypeName,
            CustomerExtendedAtribute_DateInit: us.CustomerExtendedAtribute_DateInit,
            CustomerExtendedAtribute_BotellasIniciadas: us.CustomerExtendedAtribute_BotellasIniciadas,

            CustomerExtendedAtribute_DailyDigitalizeLimit: us?.CustomerExtendedAtribute_DailyDigitalizeLimit > 0 ? us?.CustomerExtendedAtribute_DailyDigitalizeLimit : DEFAULT_LIMIT,
            CustomerExtendedAtribute_DailyRedeenLimit: us?.CustomerExtendedAtribute_DailyRedeenLimit > 0 ? us?.CustomerExtendedAtribute_DailyRedeenLimit : DEFAULT_LIMIT,
          }
          this.retornAppUser.next(retornAppUser);
          return retornAppUser;
        }),
        catchError((err:any) => {
          return of (null);
        })
      );
    }
}