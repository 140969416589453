import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FLUX } from '@src/app/constants/flux.const';
import { TraderService } from '@src/app/services/trader/trader.service';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Subscription } from 'rxjs';
import { paramsTp, resultTp } from './types';
import { ERROR_CODES, QR_KEYS_AND_TYPES_REDEEM, QR_KEYS_AND_TYPES_VIRTUALIZE, REGEX_FOR_THE_hashedKocid } from './constants';
import { AuthService, RetornAppUser } from '@src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppService } from '@src/app/services/app.service';

const enum ALERT {
  NO_PERMISSION = 'noPermission',
  INVALID_CODE = 'invalidCode'
}

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent {
  @Input() flux!: FLUX;
  public _FLUX = FLUX;
  private suscriptions: Subscription[] = [];
  public retornAppUser!: RetornAppUser | null;
  public isLoading = true;
  public showSpesificError = false;
  public contactEmail = '';

  // ? STEPS
  public step: 1 | 2 | 3 = 1;
  fullAttempts = 0;

  // ? SCANNER
  @ViewChild('scanner', { static: false }) scanner!: ZXingScannerComponent;
  public readonly allowedFormats = [BarcodeFormat.QR_CODE];
  public scannerEnabled = true;
  public scannerAutostart = true;
  public defaultDevice!: MediaDeviceInfo;
  public alertMessage!: ALERT | null;
  private qrContentAux = '';
  private dataForParameters: paramsTp = {};

  // ? COUNTER
  public maxBottles = 10;
  public readonly minBottles = 1;
  public count = 1;
  showScanner = false;

  // ? RESULT
  public result: resultTp = 'error';
  public specificError!: ALERT;

  /**
   * The constructor initializes properties and adds event listeners for the visibility change of the
   * document and subscribes to the getUserInfo() method of the authService.
   */
  constructor(
    private traderSer: TraderService,
    private authService: AuthService,
    private appService: AppService,
    private router: Router
  ) {
    this.contactEmail = this.appService.email;
    /* Suscriptions to retornAppUser that have the retornapp user information
    The subscription is then added to the `suscriptions` array to be
    unsubscribed later in the `ngOnDestroy()` function. */
    const retornAppUser$ = this.authService.retornAppUser.subscribe((response: RetornAppUser | null) => {
      if (response !== null) {
        this.retornAppUser = response;
        this.getRemainingBottles()
      }
		});
    this.suscriptions.push(retornAppUser$);
    
    /* This code adds an event listener to the `visibilitychange` event of the `document` object. This
    event is fired when the user switches to another tab or minimizes the browser window. When the
    event is fired, the callback function is executed, which calls the `activeQRScanner` method with
    the opposite value of the `hidden` property of the `document` object. This method enables or
    disables the QR scanner depending on the value of the `status` parameter. By doing this, the QR
    scanner is automatically paused when the user switches to another tab or minimizes the browser
    window, and resumes scanning when the user returns to the tab or maximizes the browser window. */
    document.addEventListener("visibilitychange", () => {
      this.activeQRScanner(!document.hidden);
    })
  }

  /**
   * The remaining number of bottles to be digitized is obtained.
   * @memberof StepsComponent
   */
  getRemainingBottles(){
    const getRemainingBottles$ = this.traderSer.getRemainingBottles({
      storeId: this.retornAppUser?.Customer_externalId?.trim() ?? '',
      traderId: this.retornAppUser?.CustomerProfile_traderId?.trim() ?? '',
      today: ((new Date()).toISOString()).split('T')[0]
    }).subscribe((response) => {
      if (response !== null) {
        this.maxBottles = response.remaining;
      }
		});
    this.suscriptions.push(getRemainingBottles$);
  }

  /**
   * This function subscribes to the camerasFound event and selects the default camera device for
   * scanning, with a fallback to the first device if no suitable device is found.
   */
  ngAfterViewInit() {
    const cameraQRReader$ = this.scanner?.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      setTimeout(() => {
        for (const device of devices) {
          // selects the devices's back camera by default'
          if (/back|rear|environment|traseira|trasera/gi.test(device.label.toLocaleLowerCase())) {
              this.defaultDevice = device;
              this.showScanner = true;
              break;
          }
        }

        if (!this.defaultDevice && devices[0]) {
          this.defaultDevice = devices[0];
        }

        if (this.defaultDevice) {
          this.isLoading = false;
          this.showScanner = true;
        }
      }, 2500);

    });
    this.suscriptions.push(cameraQRReader$);
  }
  // ? STEPS
  /**
   * The private function "nextStep()" increments the value of the "step" variable by 1.
   */
  private nextStep() {
    this.step++;
  }

  // ? SCANNER
  /**
   * The function sets the status of a QR scanner and enables or disables its autostart feature.
   * @param {boolean} status - The "status" parameter is a boolean value that determines whether the QR
   * scanner is enabled or disabled. If "status" is true, the scanner will be enabled and will
   * automatically start scanning for QR codes. If "status" is false, the scanner will be disabled and
   * will not scan for QR codes
   */
  public activeQRScanner(status: boolean) {
    this.scannerEnabled = status;
    this.scannerAutostart = status;
  }
  /**
   * The function displays an alert message indicating that cameras were not found due to lack of
   * permission.
   */
  public camerasNotFoundHandler() {
    this.showAlert(ALERT.NO_PERMISSION);
  }
  /**
   * This function handles the response of a permission request and shows an alert if the response is
   * negative.
   * @param {boolean} response - boolean parameter representing the user's response to a permission
   * request. If the user denies the permission, the showAlert method is called with the
   * ALERT.NO_PERMISSION parameter.
   */
  public permissionResponseHandler(response: boolean) {
    if (!response) this.showAlert(ALERT.NO_PERMISSION);
  }

  /**
   * This function extracts data from a string and stores it in an object, returning true if successful
   * and false if there is an error.
   * @param {string} result - The `result` parameter is a string that contains data to be extracted and
   * processed.
   * @returns The method `extractData` returns a boolean value. It returns `true` if the data is
   * successfully extracted and stored in `this.dataForParameters` and `this.count` (if applicable),
   * and it returns `false` if there is an error during the extraction process.
   */
  private extractData(result: string): boolean{
    try {
      if(this.flux === FLUX.VIRTUALIZE){
        if(
          result && 
          typeof result === QR_KEYS_AND_TYPES_VIRTUALIZE[0][1] && 
          REGEX_FOR_THE_hashedKocid.test(result)
        ){
          this.dataForParameters[QR_KEYS_AND_TYPES_VIRTUALIZE[0][0]] = result;
        }else{
          throw new Error();
        }
      }else{
        const data = JSON.parse(result);
        this.dataForParameters = {};
        
        for (const [key,type]of QR_KEYS_AND_TYPES_REDEEM) {
          if(Object.keys(data).includes(key) && typeof data[key] === type){
            if(key === "quantity"){
              this.count = data[key];
            }else{
              this.dataForParameters[key] = data[key];
            }
          }else{
            throw new Error();
          }
        }
      }
      return true;
    } catch (error) {
      return false;
    }
  }
  
  /**
   * The function handles a successful QR code scan by extracting data, showing an alert, and moving to
   * the next step.
   * @param {string} qrContent - qrContent is a string parameter that represents the content of a
   * scanned QR code. It is passed as an argument to the scanSuccessHandler function.
   * @returns If the `qrContentAux` is equal to `qrContent`, nothing is returned. If the `extractData`
   * function returns `false`, an alert with the message `ALERT.INVALID_CODE` is shown and nothing is
   * returned. Otherwise, an alert is shown and the `nextStep` function is called.
   */
  public scanSuccessHandler(qrContent: string) {
    if(this.qrContentAux === qrContent) return;
    this.qrContentAux = qrContent;
    

    if(!this.extractData(qrContent)){
      this.showAlert(ALERT.INVALID_CODE);
      return;
    }

    this.showAlert();
    this.nextStep();
  }

  /**
   * This function sets an alert message and sets isLoading to false.
   * @param {ALERT} [alertMessage] - The alertMessage parameter is an optional parameter of type ALERT.
   * If a value is provided for this parameter, it will be assigned to the alertMessage property of the
   * current object. If no value is provided, the alertMessage property will be set to null.
   */
  private showAlert(alertMessage?: ALERT) {
    this.alertMessage = alertMessage ?? null;
    this.isLoading = false;
  }

  // ? COUNTER
  /**
   * The function "counter" takes a value of either -1 or 1 and adds it to the current count.
   * @param {-1 | 1} value - The parameter "value" is a number that can only be either -1 or 1. It is
   * used to increment or decrement the "count" property of the object to which this method belongs.
   */
  public counter(value: -1 | 1) {
    this.count += value;
  }

  /**
   * The function checks if the result is 'success' and navigates to the home page, otherwise it
   * retries.
   */
  validateError() {
    if (this.result === 'success') {
      this.router.navigate(['/retornapp/home']);
      return;
    }
    this.retry();
  }

  /**
   * This function sends a request to redeem or digitalize a product and handles the response
   * accordingly.
   */
  public send() {
    this.isLoading = true;

    let method: 'redeem'|'digitalize';
    const params: paramsTp = {};

    params["customerHashedKocid"] = this.dataForParameters["hashedKocid"];
    params["storeId"] = this.retornAppUser?.Customer_externalId?.trim() ?? '';
    params["traderId"] = this.retornAppUser?.CustomerProfile_traderId?.trim() ?? '';

    if(this.flux === FLUX.REDEEM){
      method = 'redeem';
      params["eventCode"] = this.dataForParameters["eventCode"];
    } else {
      method = 'digitalize'
      params["quantity"] = this.count;
    };

    this.suscriptions.push(
      this.traderSer[method](params).subscribe({
        next: (result) => {

          if(this.hasLimitsError(result.status)){
            this.setResults("error");
          }else{
            this.setResults("success");
          }
        },
        error: (error: HttpErrorResponse) => {
          
          if (error?.status >= 500) {
            this.router.navigateByUrl('/404');
          }

          this.hasLimitsError(error.error && error.error.code ? error.error.code : undefined)
          this.setResults("error");
        }
      })
    );
  }

  /**
   * This function checks if a given error code is included in a list of limit errors and returns a
   * boolean value accordingly.
   * @param {string} code - string - represents an error code that needs to be checked for limits
   * error.
   * @returns a boolean value. It returns `true` if the input `code` is not null or undefined and is
   * included in the `LIMITS` array of `ERROR_CODES`, and `false` otherwise.
   */
  hasLimitsError(code: string): boolean{
    if(code &&  ERROR_CODES.LIMITS.includes(code)){
      this.showSpesificError = true;
      return true;  
    }
    return false;
  }

  // ? RESULT
  /**
   * The function increments the number of attempts, sets the step to 2, sets the result to success,
   * and clears the data for parameters.
   */
  public retry() {
    this.fullAttempts++;
    this.step = 2;
    this.result = 'success';
    this.dataForParameters = {};
  }

  /* The `setResults` method is setting the result of the operation (either "success" or "error") and
  updating the component's state accordingly. It also advances the step to the next one and sets the
  `isLoading` flag to false. */
  private setResults(type: resultTp) {
    this.nextStep();
    this.isLoading = false;
    this.result = type;
  };

  /**
   * The ngOnDestroy function unsubscribes from all subscriptions in the suscriptions array.
   */
  ngOnDestroy(): void {
    this.suscriptions.forEach((suscription: Subscription) => suscription.unsubscribe());
  }
}
