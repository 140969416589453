export const environment = {
  production: true,
  baseUrl: 'https://customerapi-dev.coca-cola.com/api',
  URL_B2C:'https://customer-login-dev.coca-cola.com',
  tenant: 'consumerb2ctest',
  clientID: '265efeba-a298-475f-9b9b-4520d5444f28',
  clientID_Mybar:'298fa7c6-48ef-44aa-8123-1617368c40f0',
  signUpSignIn: 'B2C_1A_DEV_signup_signin',
  URL_MYBAR: 'https://tccc-edge-mba-dev-01.azureedge.net/',
  signInWithPhone: 'B2C_1A_DEV_SIGNINPHONE',
  signUpSignInMagicLynk: 'B2C_1A_DEV_SignUpOrSigninMagicLynk',
  changePassword: 'B2C_1A_DEV_ChangePassword',
  editProfile: 'B2C_1_DEV_ProfileEdit',
  tokenTraderAuth: "45faf124-8636-4dda-b809-aafd9e85d61a",
  aud_token_mybar:"Ea489fc44-3cc0-4a78-92f6-e413cd853eae",
  function_key:"ScB6dacwCKK2nxo7GWQf0HIt399sehB/28wpvFN8Peg="
};
