import { Component, OnDestroy } from '@angular/core';
import { APP_STATUS } from '@src/app/constants/flux.const';
import { AppService } from '@src/app/services/app.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {
  private suscriptions: Subscription[] = [];
  public readonly _APP_STATUS = APP_STATUS;
  public appStatus!: APP_STATUS;
  public email!: string;

  /**
   * This is a constructor function that initializes the app status and email properties using the
   * AppService.
   */
  constructor(private readonly appService: AppService) {
    const appStatus$ = this.appService.getAppStatus().subscribe((response: APP_STATUS) => this.appStatus = response);
    this.suscriptions.push(appStatus$);

    this.email = appService.email;
  }

  /**
   * The ngOnDestroy function unsubscribes from all subscriptions in the suscriptions array.
   */
  ngOnDestroy(): void {
    this.suscriptions.forEach((suscription: Subscription) => suscription.unsubscribe());
  }
}
