<ng-container *ngIf="activeCreateUser; else app"><!-- TODO: DELETE-->
  <router-outlet></router-outlet>
</ng-container>
<app-loading *ngIf="isLoading"></app-loading>
<ng-template #app><!-- TODO: DELETE-->

<div class="app" [class.padding-bottom]="!disablePaddingBottom">
  <app-header></app-header>
  <div class="container-fluid p-0" [class.retornapp]="[_APP_STATUS.RETORN_APP_BOTTLER, _APP_STATUS.RETORN_APP_TENDERO].includes(appStatus)">
    <aside class="z7">
      <app-nav></app-nav>
    </aside>
    <main data-bs-toggle="collapse" data-bs-target=".collapse.show" (click)="detectChanges()">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
<app-footer data-bs-toggle="collapse" data-bs-target=".collapse.show" (click)="detectChanges()"></app-footer>
</ng-template><!-- TODO: DELETE-->


<!-- Button trigger modal -->
<button type="button" id="btnOpenNoInternetConectionModal" data-bs-toggle="modal" data-bs-target="#noInternetConectionModal" hidden></button>
<!-- Modal -->
<div class="modal fade" id="noInternetConectionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="noInternetConectionModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content expired-session-container">
      <div class="modal-header justify-content-center text-center border-bottom-0 p-4 mt-4">
        <button data-bs-dismiss="modal" id="btnCloseNoInternetConectionModal" hidden></button>
        <h5 class="modal-title ff-unity-headline-regular fs24-lh28">
          {{ "noInternetConection.title" | translate }}
        </h5>
      </div>
      <div class="modal-body mt-5 text-center">
        <span class="icon-Miss3x">
          <span class="path1"></span><span class="path2"></span
          ><span class="path3"></span><span class="path4"></span
          ><span class="path5"></span><span class="path6"></span
          ><span class="path7"></span><span class="path8"></span
          ><span class="path9"></span><span class="path10"></span
          ><span class="path11"></span><span class="path12"></span
          ><span class="path13"></span><span class="path14"></span
          ><span class="path15"></span><span class="path16"></span
          ><span class="path17"></span><span class="path18"></span
          ><span class="path19"></span><span class="path20"></span
          ><span class="path21"></span><span class="path22"></span
          ><span class="path23"></span><span class="path24"></span
          ><span class="path25"></span><span class="path26"></span
          ><span class="path27"></span><span class="path28"></span
          ><span class="path29"></span><span class="path30"></span
          ><span class="path31"></span><span class="path32"></span
          ><span class="path33"></span><span class="path34"></span>
        </span>
      </div>
      <div class="modal-footer justify-content-center border-top-0 my-4 p-4">
        <p class="ff-unity-headline-regular fs16-lh24 text-center" style="max-width: 21rem;">
          {{ "noInternetConection.description" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Button trigger modal -->
<button type="button" id="btnOpenExpiredSessionModal" data-bs-toggle="modal" data-bs-target="#expiredSessionModal" hidden></button>
<!-- Modal -->
<div class="modal fade" id="expiredSessionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="expiredSessionModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content expired-session-container py-3">
      <div class="modal-header justify-content-center text-center pb-0 border-bottom-0">
        <h5 class="modal-title ff-unity-headline-bold fs28-lh40" id="expiredSessionModalLabel">
          {{ "sessionExpired.title" | translate }}
        </h5>
      </div>
      <div class="modal-body pt-0 text-center">
        <p class="ff-unity-headline-regular fs18-lh24 mb-5">
          {{ "sessionExpired.description" | translate }}
        </p>
      
        <span class="icon-Time3x">
          <span class="path1"></span><span class="path2"></span
          ><span class="path3"></span><span class="path4"></span
          ><span class="path5"></span><span class="path6"></span
          ><span class="path7"></span><span class="path8"></span
          ><span class="path9"></span><span class="path10"></span
          ><span class="path11"></span><span class="path12"></span
          ><span class="path13"></span><span class="path14"></span
          ><span class="path15"></span><span class="path16"></span
          ><span class="path17"></span><span class="path18"></span
          ><span class="path19"></span><span class="path20"></span
          ><span class="path21"></span><span class="path22"></span
          >
        </span>
      
      </div>
      <div class="modal-footer justify-content-center border-top-0">
        <button
            class="primary-button m-0"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Return to home"
            (click)="closeSession()"
          >
            {{ "buttons.accept" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>