import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { LENGUAJES } from '@src/app/constants/lenguajes.const';
import { AppService } from '@src/app/services/app.service';
import { AuthService, UserPermission } from '@src/app/services/auth.service';
import { TranslationService } from '@src/app/services/translate/translation.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from '@src/environments/environment';


import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { APP_STATUS } from '@src/app/constants/flux.const';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public readonly languages = LENGUAJES;
  public readonly _APP_STATUS = APP_STATUS;
  private suscriptions: Subscription[] = [];
  public user!: UserPermission | null;

  public appStatus!: APP_STATUS;

  /**
   * This is a constructor function that initializes various services
   */
  constructor(
    private readonly appService: AppService,
    private localAuthService: AuthService,
    public translation: TranslationService,
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private cdRef: ChangeDetectorRef
  ) {
  }
  /**
   * The ngOnInit function subscribes to two observables and pushes them into an array of
   * subscriptions.
   */
  ngOnInit() {
    const appStatus$ = this.appService.getAppStatus().subscribe((response: APP_STATUS) => this.appStatus = response);
    this.suscriptions.push(appStatus$);
    const userPermissions$ = this.localAuthService.getUserPermissions().subscribe((response: UserPermission | null) => this.user = response);
    this.suscriptions.push(userPermissions$);
  }

  /**
   * This function calls the detectChanges method of the cdRef object.
   */
  public detectChanges() {
    this.cdRef.detectChanges();
  }
  /**
   * This function logs in the user with a redirect request and sets the UI language based on the
   * current language.
   * @returns nothing (undefined).
   */
  public login() {
    const tenant = environment.tenant;
    const clientID = environment.clientID;
    this.appService.isLoading.next(true);
    if (!this.msalGuardConfig.authRequest) return;
    const authRequestConfig = { ...this.msalGuardConfig.authRequest } as RedirectRequest
    authRequestConfig.extraQueryParameters = {"ui_locales" : this.translation.currentLang.proxy.lang.toUpperCase()};
    const scopeAPI = `https://${tenant}.onmicrosoft.com/${clientID}/AccessCMDP`;
    this.authService.loginRedirect({ ...authRequestConfig, scopes: [scopeAPI] });
    this.appService.isLoading.next(false);
    return;
  }
  /**
   * The function logs out the user and sets the isLoading flag to true.
   */
  public logout() {
    this.router.navigateByUrl('/logout');
  }

  /**
   * The ngOnDestroy function unsubscribes from all subscriptions in the suscriptions array.
   */
  ngOnDestroy(): void {
    this.suscriptions.forEach((suscription: Subscription) => suscription.unsubscribe());
  }
}
