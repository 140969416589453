import { Injectable } from '@angular/core';

/* SERVICES */
import { HttpService } from '../http/http.service';
import { TQueryGetRemainingBottles, TQueryTransactionHistory } from '../../features/history/types';

@Injectable({
    providedIn: 'root'
  })
export class TraderService {
    /**
     * Creates an instance of TraderService.
     */
    constructor( private httpService: HttpService ) {}

    /**
     * This function sends a POST request to a specific endpoint for authentication.
     * @returns The `auth()` function is returning a `Promise` object that represents the result of a
     * POST request to the URL `/retornapp/trader/auth` with an empty object as the request body. The
     * `true` parameter indicates that the request should include authentication credentials.
     */
    auth(){
        return this.httpService.post('/retornapp/trader/auth', {},true, true);
    }

    /**
     * The digitalize function sends a post request to a specific endpoint with given parameters.
     * @param {any} params - The `params` parameter is an object that contains the data to be sent.
     * @returns The `digitalize` function is returning the result of a POST request to the URL
     * `/retornapp/trader/virtualization` with the provided `params` as the request body. The `true`
     * parameter indicates that the request should be made with authentication.
     */
    digitalize(params: any){
        return this.httpService.post('/retornapp/trader/virtualization', params, true, true);
    }

    /**
     *The getSwagger function gives us access to the swagger YAML file, to be able to test the api services
     *
     * @param {*} params
     * @return {*} 
     * @memberof TraderService
     */
     getSwagger(){
        return this.httpService.get('/swaggerUrl', false, false);
    }

    /**
     * The function "redeem" sends a POST request to a specific endpoint with given parameters.
     * @param {any} params - The `params` parameter is an object that contains the data to be sent.
     * @returns The `redeem` function is returning the result of a POST request made using the
     * `httpService` object. The request is being sent to the `/retornapp/trader/redeem` endpoint with
     * the `params` object as the request body. The `true` parameter indicates that the request should
     * be made with authentication.
     */
    redeem(params: any){
        return this.httpService.post('/retornapp/trader/redeem', params, true, true);
    }

    /**
     * This function retrieves transaction history data for a trader.
     * @param {TQueryTransactionHistory} query - TQueryTransactionHistory that contains the data to be sent.
     * @returns The `transactionHistory` function is returning the result of an HTTP GET request to the
     * endpoint `/retornapp/trader/transaction-history`, passing the `query` object as a parameter and
     * setting the `true` flag to indicate that the request should be authenticated.
     */
    transactionHistory(query: TQueryTransactionHistory){
        return this.httpService.get('/retornapp/trader/transaction-history', query, true, true);
    }

    /**
     * This function retrieves transaction history for a trader's wallet.
     * @param {TQueryTransactionHistory} query - The parameter `query` contains the data to be sent.
     * @returns The `wallet` function is returning the result of an HTTP GET request to the endpoint
     * `/retornapp/trader/wallet` with the query parameters specified in the `query` object. The third
     * parameter `true` indicates that the request should be authenticated.
     */
    wallet(query: TQueryTransactionHistory){
        return this.httpService.get('/retornapp/trader/wallet', query, true, true);
    }

    /**
     * The daily limit of bottles to be digitized, the number of bottles digitized and the number of 
     * bottles remaining to reach the limit are obtained.
     * @param {TQueryGetRemainingBottles} query
     * @return {*} 
     * @memberof TraderService
     */
    getRemainingBottles(query: TQueryGetRemainingBottles){
        return this.httpService.get('/retornapp/trader/getRemainingBottles', query, true, true);
    }
}