<div class="container return-buttonxxx px-4">
  <div class="row justify-content-center color-black  mt-4 pt-0 mt-md-5 pt-md-4 mb-4 pb-2 mb-md-4 pb-md-3">
    <div class="col-12">
      <a
        class="btn ff-unity-headline-light fs14-lh24 ps-1" 
        [routerLink]="['/retornapp/home']"
        aria-label="Return to home"
      >
        <span class="icon-Left3x fz-0_75rem me-2"></span>
        {{ "buttons.returnTo" | translate }}
      </a>
    </div>
  </div>
  <div class="row d-flex" style="margin-bottom: 40px">
    <span class="breadcrumb-disable  ff-unity-headline-regular" style="width: fit-content; cursor: pointer;" [routerLink]="'/retornapp/home'"
      >RetornApp</span
    >
    <div class="icon-up3x icon-arrow"></div>
    <span
      class="breadcrumb-disable"
      style="width: fit-content; color: #c51235"
      (click)="reloadPage()"
      >{{ flux === _FLUX.VIRTUALIZE ? ("instructions.digitalize.title" | translate) :( "instructions.redeem.title"| translate)  }}</span
    >
  </div>

</div>

<div class="container-full pb-3" style="margin-top: 40px;">
  <div class="row m-0">
    <div
      class="col-12 col-lg-6 qr-side px-0 d-flex flex-column align-items-center"
      [ngClass]="flux === _FLUX.VIRTUALIZE ? 'order-0' : 'order-1'"
    >
      <p class="title-qr">
        {{ flux === _FLUX.VIRTUALIZE ? ("instructions.digitalize.title" | translate) :( "instructions.redeem.title"| translate)  }}
      </p>
      <div style="position: relative;" *ngIf="imgQr; else buildingQr">
        <img [src]="center_image" class="custom-icon-qr" alt="">
        <img [src]="imgQr.__zone_symbol__value" alt="" style="image-rendering: pixelated;">
      </div>
      <ng-template #buildingQr>
        <div class="position-relative">
          <span class="loader"></span>
        </div>
      </ng-template>
      
    </div>
    <div
      class="col-12 col-lg-6 steps-side px-0"
      [ngStyle]="{
        'background-image':
          'url(' +
          (flux === _FLUX.VIRTUALIZE
            ? 'assets/png/steps/escanea.png'
            : 'assets/png/steps/canjea-banner.png') +
          ')'
      }"
    >
      <div class="cover-image"></div>
      <div class="content-steps h-100 d-flex">
        <div
          class="d-flex flex-column justify-content-center h-100 custom-dots"
      
        >
          <div
            class=""
            *ngFor="let dots of fluxToshow; index as i"
            [ngClass]="dots.active ? 'circle-active' : 'circle'"
            (click)="changeActive(i)"
          ></div>
        </div>
        <div class="d-flex flex-column">
          <div
            class="d-flex"
            *ngFor="let steps of fluxToshow"
            style="max-width: 336px"
            [ngClass]="steps.active ? 'active-step' : 'disabled-text'"
          >
            <div
              class="active-option"
              [ngClass]="steps.active ? 'active-selected' : ''"
            ></div>
            <div>
              <p class="step-title">{{ steps.step | translate }}</p>
              <p class="step-text">{{ steps.description | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
