<div class="footer-background px-4 py-5 position-relative z9 bottom-0">
  <div class="row align-items-center">
    <div
      class="col-12 col-md-auto my-4 d-flex justify-content-center justify-content-md-start"
    >
      <a
        class="text-decoration-none pe-3 d-inline-block text-end text-md-start border-end border-md-1 border-white"
        routerLink="/"
        aria-label="Return to home"
      >
        <span class="icon-coca-cola-logo-withe3x fz-2rem"></span>
      </a>
      <a
        class="text-decoration-none d-inline-block ps-3"
        routerLink="/"
        aria-label="Return to home"
      >
        <img
          *ngIf="[_APP_STATUS.LANDING, _APP_STATUS.LANDING_HOME].includes(appStatus); else retornAppTemplate"
          src="assets/svg/CP-logo.svg"
          alt="Customer Portal logo"
          class="cp-logo"
        />
        <ng-template #retornAppTemplate>
          <img
            src="assets/png/logo-CP@2x.png"
            alt="RetornApp logo"
            class="cp-logo"
          />
        </ng-template>
      </a>
    </div>

    <div
      class="col-12 col-md mt-3 mt-md-0 ff-unity-headline-regular fs12-lh16 color-white text-center text-md-end"
    >
      <a
        class="d-inline-block text-decoration-none color-white mb-2"
        routerLink="/terms-and-conditions"
        aria-label="Go to terms and conditions"
      >
        {{ "termsAndConditions.title" | translate }}
      </a>
      <p class="d-inline-block m-1">|</p>
      <a
        class="d-inline-block text-decoration-none color-white mb-2"
        routerLink="/cookies"
        aria-label="Go to cookies"
      >
        {{ "footer.cookies" | translate }}
      </a>
      <p class="mb-2">
        © The Coca-Cola Company, {{ "footer.copyright" | translate }}
      </p>
      <!-- <p class="d-inline-block mb-2">
        {{ "footer.contactUs" | translate }} {{ email }}
      </p> -->
    </div>
  </div>
</div>
