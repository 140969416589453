<nav class="navbar px-2 px-sm-3 px-md-4 py-4">
  <!-- ? Collapse menu -->
  <div class="col-auto col-md-4">
    <button
      class="btn toggle-nav px-2 py-1"
      data-bs-toggle="collapse"
      data-bs-target="#collapseNavFromHeader"
      aria-expanded="false"
      aria-controls="collapseNavFromHeader"
      aria-label="Toggle menu"
      #collapsenav
      (click)="detectChanges()"
    >
      <span
          [ngClass]="
            collapsenav?.getAttribute('aria-expanded') !== 'true'
              ? 'icon-menu-burguer3x'
              : 'icon-icon-collapesd3x'
          "
          class="fz-1_2rem"
        ></span>
    </button>
  </div>
  <!-- ? Company logo -->
  <div class="col col-md-4 d-flex justify-content-center">
    <a
      class="text-decoration-none pe-3 col-5 col-sm-6 text-end border-end border-md-1 border-white"
      routerLink="/"
      aria-label="Return to home"
    >
      <span class="icon-coca-cola-logo-withe3x fz-2rem"></span>
    </a>
    <a
      class="text-decoration-none d-inline-block ps-3 col-7 col-sm-6"
      routerLink="/"
      aria-label="Return to home"
    >
      <img
        *ngIf="[_APP_STATUS.LANDING, _APP_STATUS.LANDING_HOME].includes(appStatus); else retornAppTemplate"
        src="assets/svg/CP-logo.svg"
        alt="Customer Portal logo"
        class="cp-logo"
      />
      <ng-template #retornAppTemplate>
        <img
          src="assets/png/logo-CP@2x.png"
          alt="RetornApp logo"
          class="cp-logo"
        />
      </ng-template>

    </a>
  </div>
  <div class="col-4 d-none d-md-block justify-content-end">
    <ul class="options color-white">
      <!-- ? Collapse lenguaje selector -->
      <li class="option">
        <div id="lenguaje" class="dropdown">
          <button
            class="btn btn-dark dropdown-toggle ff-unity-headline-bold fs18-lh24 color-white"
            type="button"
            id="dropdownLenguages"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            aria-label="Toggle lenguaje selector"
          >
            {{ translation.currentLang.proxy.lang.toLocaleUpperCase() }}
          </button>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdownLenguages"
          >
            <li *ngFor="let lenguaje of languages" class="position-relative">
              <button
                class="dropdown-item ff-unity-headline-regular fs14-lh24"
                type="button"
                (click)="translation.changeLanguage(lenguaje[0])"
                [class.color-red-dark]="
                  translation.currentLang.proxy.lang === lenguaje[0]
                "
                aria-label="Select lenguaje"
              >
                {{ lenguaje[1] | translate }}
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li class="option">
        <!-- ? LOGIN -->
        <ng-container *ngIf="appStatus === _APP_STATUS.LANDING">
          <a
            class="primary-button fs12-lh16"
            aria-label="Go to login"
            (click)="login()"
          >
            {{ "landing.header.menu.login" | translate }}
          </a>
        </ng-container>
        <!-- ? User -->
        <ng-container *ngIf="[_APP_STATUS.LANDING_HOME, _APP_STATUS.RETORN_APP_BOTTLER, _APP_STATUS.RETORN_APP_TENDERO].includes(appStatus)">
          <div id="user" class="dropdown">
            <button
              class="btn btn-dark after-none dropdown-toggle px-2 py-0"
              type="button"
              aria-label="Toggle user info"
              id="dropdownUser"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="icon-profile3x px-auto" style="font-size: 1.75rem">
                <span class="path1"></span><span class="path2"></span
                ><span class="path3"></span><span class="path4"></span
                ><span class="path5"></span><span class="path6"></span>
              </span>
            </button>
            <ul
              class="dropdown-menu dropdown-menu-end mt-2 ff-unity-headline-regular fs14-lh24"
              aria-labelledby="dropdownUser"
            >
              <li class="position-relative">
                <button class="dropdown-item px-0 d-flex" aria-label="User info">
                  <img
                    [src]="user?.avatar"
                    [alt]="user?.firstName + 'avatar'"
                    onerror="this.src='assets/svg/avatar.svg'"
                    class="avatar px-auto my-1"
                  />
                  <div class="ms-2 w-75">
                    <p class="ff-unity-headline-regular fs16-lh24 mb-0">
                      {{ "header.option.hello" | translate }},
                    </p>
                    <p class="ff-unity-headline-bold fs16-lh24 mb-0 trunk-1-line">
                      {{ user?.firstName }}!
                    </p>
                  </div>
                </button>
              </li>
              <li class="position-relative">
                <a
                  class="dropdown-item"
                  routerLink="/retornapp/account"
                  aria-label="Go to account"
                >
                  <span class="icon-profile3x1 fz-1rem"></span>
                  <span class="ms-3">
                    {{ "header.option.account" | translate }}
                  </span>
                </a>
              </li>
              <li class="position-relative">
                <a
                  class="dropdown-item"
                  (click)="logout()"
                  aria-label="Close session"
                >
                  <span class="icon-Logout3x fz-1rem"></span>
                  <span class="ms-3">
                    {{ "header.option.exit" | translate }}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </ng-container>
      </li>
    </ul>
  </div>
</nav>
