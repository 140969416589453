import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService, UserPermission } from '../services/auth.service';
import { log } from 'console';

@Injectable({
  providedIn: 'root'
})


export class RetornappAdminGuard implements OnDestroy {

  public user!: UserPermission | null;
  private suscriptions: Subscription[] = [];
  
  constructor(
    private router: Router,
    private readonly authService: AuthService
  ) {
   
    
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any{
    const userPermissions$ = this.authService.getUserPermissions().subscribe((response: UserPermission | null) => {
     if (response) {
      this.user = response;
      setTimeout(() => {
       if (this.user?.app[0].rol === 'admin') {
         return true;
       } else {
         this.router.navigate(['/404']);
         return false;
       }
      },500);
     }
    });
    this.suscriptions.push(userPermissions$);
    
  }

    /**
   * The ngOnDestroy function unsubscribes from all subscriptions in the suscriptions array.
   */
    ngOnDestroy(): void {
      this.suscriptions.forEach((suscription: Subscription) => suscription.unsubscribe());
    }
  
}
