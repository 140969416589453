<app-loading *ngIf="isLoading"></app-loading>
<div class="container steps-container position-relative" *ngIf="!showSpesificError; else specificErrorTemplate">
  <ng-container [ngTemplateOutlet]="backBottomTemplate"></ng-container>
  <!-- ? STEPS -->
  <div class="row justify-content-center mt-4">
    <div class="col-auto">
      <button class="py-2 px-3 mx-2 btn bg-transparent border-0">
        <span class="step" [class.active]="step === 1"></span>
      </button>
      <button class="py-2 px-3 mx-2 btn bg-transparent border-0">
        <span class="step" [class.active]="step === 2"></span>
      </button>
      <button class="py-2 px-3 mx-2 btn bg-transparent border-0">
        <span class="step" [class.active]="step === 3"></span>
      </button>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12 content text-center" [ngSwitch]="step">
      <!-- ? SCANNER -->
      <ng-container
        *ngSwitchCase="1"
        [ngTemplateOutlet]="scannerTemplate"
      ></ng-container>
      <!-- ? COUNTER -->
      <ng-container
        *ngSwitchCase="2"
        [ngTemplateOutlet]="counterTemplate"
      ></ng-container>
      <!-- ? SUCCESS -->
      <ng-container
        *ngSwitchCase="3"
        [ngTemplateOutlet]="resultTemplate"
      ></ng-container>
      <div *ngSwitchDefault>
        Sorry, you're not authorized to view this content.
      </div>
    </div>
  </div>
  <div 
    *ngIf="alertMessage"
    class="alert-container"
  >
    <p class="ff-unity-headline-regular fs18-lh24 text-center mb-1">
      {{ "steps.scaner.error." + alertMessage | translate }}
    </p>
  </div>
</div>
<ng-template #specificErrorTemplate>
  <div class="container steps-container mt-4 position-relative">
    <ng-container [ngTemplateOutlet]="backBottomTemplate"></ng-container>
    <div class="row mt-5">
      <div class="col-8 mx-auto mt-5 text-center">
        <h4 class="ff-unity-headline-bold fs28-lh40 black-color mb-5">
          {{ "steps.specificError.exceededLimit." + flux | translate }}
        </h4>
        <span class="icon-limite3x" style="font-size: 8.875rem;" >
          <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span><span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span><span class="path25"></span><span class="path26"></span>
        </span>
      </div>
    </div>
   
  </div>
</ng-template>
<ng-template #scannerTemplate>
  <div class="scanner mx-auto position-relative">
    <zxing-scanner
    [hidden]="!showScanner"
      #scanner
      [autofocusEnabled]="false"
      [autostart]="scannerAutostart"
      [enable]="scannerEnabled"
      [formats]="allowedFormats"
      [device]="defaultDevice"
      (camerasNotFound)="camerasNotFoundHandler()"
      (scanSuccess)="scanSuccessHandler($event)"
      (permissionResponse)="permissionResponseHandler($event)"
    >
    </zxing-scanner>
  </div>

  <h5 class="ff-unity-headline-light fs20-lh24 black-color mt-5">
    {{ "steps.scaner.description" | translate }}
  </h5>
</ng-template>
<ng-template #counterTemplate>
  <h5
    class="ff-unity-headline-light fs20-lh24 black-color mt-4 mx-auto mw-title"
  >
    {{ "steps." + flux + ".title" | translate }}
  </h5>
  <p
    class="ff-unity-headline-light fs14-lh24 color-red-dark mt-2 mx-auto mw-description"
  >
    {{
      "steps." + flux + ".description" | translate : { maxBottles }
    }} 
  </p>
  <hr class="mt-3 w-75 mx-auto" />
  <section
    class="counter d-flex justify-content-center align-items-center pb-3 mt-3_5rem"
    [class.counter-long]="count >= 100"
  >
    <button
      *ngIf="flux === _FLUX.VIRTUALIZE"
      class="control bg-transparent"
      (click)="counter(-1)"
      [disabled]="count === minBottles"
    >
      <span class="icon-min-circle3x"></span>
    </button>
    <input
      type="text"
      class="no-spinner text-center mx-4"
      [(ngModel)]="count"
      disabled
    />

    <button
      *ngIf="flux === _FLUX.VIRTUALIZE"
      class="control bg-transparent"
      (click)="counter(1)"
      [disabled]="count === maxBottles  || maxBottles === 0"
    >
      <span class="icon-plus-circle3x"></span>
    </button>
  </section>
  <button class="primary-button mt-5" (click)="send()"  [disabled]="maxBottles === 0 && flux === _FLUX.VIRTUALIZE">
    {{ "steps." + flux + ".action" | translate }}
  </button>
</ng-template>
<ng-template #resultTemplate>
  <section class="result-container mx-auto pt-5">
    <h4 class="ff-unity-headline-bold fs32-lh40 black-color mt-3 mb-0">
      {{
        (result === "success"
          ? "steps." + result + ".title"
          : "steps." + result + ".title." + flux
        ) | translate
      }}
    </h4>
    <h5
      class="ff-unity-headline-bold fs20-lh24 black-color mt-4 mb-0 mx-auto mw-description"
    >
      {{
        (result === "success"
          ? "steps." + result + ".description." + flux
          : "steps." + result + ".description"
        ) | translate
      }}
    </h5>
    <div class="ff-unity-headline-regular fs16-lh24 mt-3" *ngIf="fullAttempts > 3">
      {{ "steps." + result + ".full_attepmts" | translate: { contactEmail } }}
    </div>
    <p class="text-center mt-5">
      <span
        class="icon-Ok3x"
        *ngIf="result === 'success'; else errorTemplate"
      ></span>
      <ng-template #errorTemplate>
        <span class="icon-reintentar3x" (click)="retry()"></span>
      </ng-template>
    </p>
    <button class="primary-button mt-4" (click)="validateError()">
      {{ "buttons.returnTo" | translate }}
    </button>
  </section>
</ng-template>


<ng-template #backBottomTemplate>
  <div class="row justify-content-center color-black  mt-4 pt-0 mt-md-5 pt-md-4 mb-4 pb-2 mb-md-4 pb-md-3 px-4">
    <div class="col-12">
      <a
        class="btn ff-unity-headline-light fs14-lh24 ps-1"
        [routerLink]="['/retornapp/home']"
        aria-label="Return to home"
      >
        <span class="icon-Left3x fz-0_75rem me-2"></span>
        {{ "buttons.returnTo" | translate }}
      </a>
    </div>
  </div>
</ng-template>