import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicGuard } from '@app/guard/public.guard';
import { MsalGuard } from '@azure/msal-angular';
import { CustomerPortalPrivateGuard } from './guard/customer-portal-private.guard';
import { RetornAppPrivateGuard } from './guard/retornapp-private.guard';
import { RetornAppBottlerPrivateGuard } from './guard/retornapp-bottler-private.guard';
import { RetornappAdminGuard } from './guard/retornapp-admin.guard';

const retornAppTitle = 'RetornApp';
const customerPortalTitle = 'Customer Portal';

export const routes: Routes = [
  // ? CUSTOMER PORTAL
  { path: '', loadChildren: () => import('./landing/landing/landing.module').then(m => m.LandingModule), title: customerPortalTitle, canActivate: [PublicGuard] },
  { path: 'portal', loadChildren: () => import('./landing/platform/platform.module').then(m => m.PlatformModule), title: `${customerPortalTitle} | Home`, canMatch: [MsalGuard], canActivateChild: [CustomerPortalPrivateGuard] },
  { path: 'terms-and-conditions', loadComponent: () => import('./landing/terms-conditions/terms-conditions.component').then(m => m.TermsConditionsComponent), title: `Terms and conditions` },

  { path: 'cookies', loadComponent: () => import('./landing/cookies/cookies.component').then(m => m.CookiesComponent), title: `Cookies` },

  // ? RETORNAPP
  { path: 'retornapp/home', loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule), title: retornAppTitle, canMatch: [MsalGuard], canActivateChild: [RetornAppPrivateGuard] },
  { path: 'retornapp/digitalize',
    loadChildren: () => import('./features/virtualize/virtualize.module').then(m => m.VirtualizeModule),
    title: `${retornAppTitle} | Digitalize`,
    canMatch: [MsalGuard], 
    canActivateChild: [RetornAppPrivateGuard]
  },
  { path: 'retornapp/redeem',
    loadChildren: () => import('./features/redeem/redeem.module').then(m => m.RedeemModule),
    title: `${retornAppTitle} | Redeem`,
    canMatch: [MsalGuard], 
    canActivateChild: [RetornAppPrivateGuard]
  },
  { path: 'retornapp/history', loadChildren: () => import('./features/history/history.module').then(m => m.HistoryModule), title: `${retornAppTitle} | History`, canMatch: [MsalGuard], canActivateChild: [RetornAppPrivateGuard] },

  // ? BOTTLER
  { path: 'retornapp/store', loadComponent: () => import('./bottler/store/store.component').then(m => m.StoreComponent), title: `${retornAppTitle} | Store`,  canMatch: [MsalGuard], canActivate: [RetornAppBottlerPrivateGuard] },
  { path: 'retornapp/bottler', loadComponent: () => import('./bottler/bottler/bottler.component').then(m => m.BottlerComponent), title: `${retornAppTitle} | Bottler`,  canMatch: [MsalGuard], canActivate: [RetornAppBottlerPrivateGuard] },
  { path: 'retornapp/load-stores', loadComponent: () => import('./bottler/load-stores/load-stores.component').then(m => m.LoadStoresComponent), title: `${retornAppTitle} | Load stores`,  canMatch: [MsalGuard], canActivate: [RetornAppBottlerPrivateGuard] },
  { path: 'retornapp/guide', loadComponent: () => import('./bottler/guide/guide.component').then(m => m.GuideComponent), title: `${retornAppTitle} | Guide`,  canMatch: [MsalGuard], canActivate: [RetornAppBottlerPrivateGuard] },

  // ! DELETE
  { path: 'create-user', loadComponent: () => import('./create-user/create-user.component').then(m => m.CreateUserComponent), title: `Create user`, canMatch: [MsalGuard], canActivate: [RetornappAdminGuard] },

  // ? SHARED ROUTES
  { path: 'auth', loadComponent: () => import('./features/login/login.component').then(m => m.LoginComponent), title: `Login...`, },
  { path: 'retornapp/account', loadComponent: () => import('./features/account/account.component').then(m => m.AccountComponent), title: `${retornAppTitle} | Account`, canMatch: [MsalGuard] },

  { path: 'logout', loadComponent: () => import('./shared/logout/logout.component').then(m => m.LogoutComponent), title: `${retornAppTitle} | Account`,},
  { path: '404', loadComponent: () => import('./shared/error/error.component').then(m => m.ErrorComponent), title: `Not found`, },
  { path: '**', redirectTo: '404' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top", initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
