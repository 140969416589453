import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructionsComponent } from './instructions/instructions.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LoadingComponent } from './loading/loading.component';
import { StepsComponent } from './steps/steps.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

@NgModule({
  declarations: [
    InstructionsComponent,
    StepsComponent
  ],
  imports: [ 
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    LoadingComponent,
    ZXingScannerModule
  ],
  exports: [
    InstructionsComponent,
    StepsComponent
  ]
})
export class SharedModule { }
