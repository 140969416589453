<div class="loading-container requires-no-scroll z10">
  <span class="icon-Botella3x">
    <span class="path1"></span><span class="path2"></span
    ><span class="path3"></span><span class="path4"></span
    ><span class="path5"></span><span class="path6"></span>
  </span>
  <h5 class="ff-unity-headline-bold fs18-lh24 color-white py-4">
    {{ "util.loading" | translate }}...
  </h5>
</div>
