import { Component, Inject } from '@angular/core';
import { APP_STATUS } from '@src/app/constants/flux.const';
import { LENGUAJES } from '@src/app/constants/lenguajes.const';
import { AppService } from '@src/app/services/app.service';
import { TranslationService } from '@src/app/services/translate/translation.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from '@src/environments/environment';

import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { Router } from '@angular/router';

interface RetornAppMenu {
  redirectTo: string,
  title: string,
  icon: string,
}
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  public menu: RetornAppMenu[]= [];
  /* `private readonly menuTendero` is an array of objects that contains the menu items for the
  navigation bar of the RetornApp application. It is used to set the menu for the tendero user type.
  Each object contains three properties: `redirectTo` which is the route to which the menu item
  should redirect, `title` which is the translation key for the menu item title, and `icon` which is
  the name of the icon to be displayed next to the menu item. */
  private readonly menuTendero = [
    {
      redirectTo: 'portal',
      title: 'header.menu.portal',
      icon: 'Home3x'
    },
    {
      redirectTo: 'retornapp/home',
      title: 'header.menu.home',
      icon: 'Retornapp-logo3x'
    },
    {
      redirectTo: 'retornapp/digitalize',
      title: 'header.menu.digitalize',
      icon: 'digitalizar3x'
    },
    {
      redirectTo: 'retornapp/redeem',
      title: 'header.menu.redeem',
      icon: 'canjea3x'
    },
    {
      redirectTo: 'retornapp/history',
      title: 'header.menu.history',
      icon: 'historial3x'
    }
  ]
  /* `private readonly menuBottler` is an array of objects that contains the menu items for the
  navigation bar of the RetornApp application. It is used to set the menu for the bottler user type. Each
  object contains three properties: `redirectTo` which is the route to which the menu item should
  redirect, `title` which is the translation key for the menu item title, and `icon` which is the
  name of the icon to be displayed next to the menu item. */
  private readonly menuBottler = [
    {
      redirectTo: 'portal',
      title: 'header.menu.portal',
      icon: 'Home3x'
    },
    {
      redirectTo: 'retornapp/bottler',
      title: 'header.menu.home',
      icon: 'Retornapp-logo3x'
    },
    {
      redirectTo: 'retornapp/load-stores',
      title: 'header.menu.loadStores',
      icon: 'upload3x'
    },
    {
      redirectTo: 'retornapp/store',
      title: 'header.menu.stores',
      icon: 'store3x'
    },
  ]
  /* The `menuLanding` variable is an array of arrays that contains the menu items for the landing
  page. Each sub-array contains two elements: the first element is the route to which the menu item
  should redirect, and the second element is the translation key for the menu item title. */
  public readonly menuLanding = [
    ['', 'landing.header.menu.home'],
    ['login', 'landing.header.menu.login'],
    ['aboutus', 'landing.header.menu.aboutus'],
    ['faq', 'landing.header.menu.faq']
  ];

  public appStatus!: APP_STATUS;
  private suscriptions: Subscription[] = [];
  public readonly languages = LENGUAJES;
  public readonly _APP_STATUS = APP_STATUS;

  /**
   * This is a constructor function that takes in several dependencies including AppService,
   * TranslationService, MsalGuardConfiguration, and MsalService.
   */
  constructor(
    private readonly appService: AppService,
    public translation: TranslationService,
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
  ) {}

  /**
   * The ngOnInit function subscribes to an observable that retrieves the app status and updates the
   * appStatus variable while also calling the evaluateMenu function.
   */
  ngOnInit() {
    const appStatus$ = this.appService.getAppStatus().subscribe((response: APP_STATUS) => {
      this.appStatus = response;
      this.evaluateMenu();
    });
    this.suscriptions.push(appStatus$);
  }

  /**
   * The function evaluates the app status and sets the menu accordingly.
   */
  private evaluateMenu() {
    this.menu = this.appStatus === APP_STATUS.RETORN_APP_BOTTLER ? [...this.menuBottler] : [...this.menuTendero];
  }

  /**
   * The function logs in the user with a redirect request and sets the UI language based on the
   * current language.
   */
  public login() {
    const tenant = environment.tenant;
    const clientID = environment.clientID;
    this.appService.isLoading.next(true);
    
    if (!this.msalGuardConfig.authRequest) {
      return;
    }
    const authRequestConfig = { ...this.msalGuardConfig.authRequest } as RedirectRequest
    const scopeAPI = `https://${tenant}.onmicrosoft.com/${clientID}/AccessCMDP`;
    authRequestConfig.extraQueryParameters = {"ui_locales" : this.translation.currentLang.proxy.lang.toUpperCase()};
    this.authService.loginRedirect({ ...authRequestConfig, scopes: [scopeAPI] });
    return;
  }

  /**
   *Funcion para redirigir a portal
   *
   * @memberof NavComponent
   */
  redirToPortal() {
    this.appService.setAppStatus(APP_STATUS.LANDING_HOME);
    this.router.navigate(['/portal']);
  }
  /**
   * The function logs out the user and sets the isLoading flag to true.
   */
  public logout() {
    this.router.navigateByUrl('/logout');
  }
  /**
   * The ngOnDestroy function unsubscribes from all subscriptions in the suscriptions array.
   */
  ngOnDestroy(): void {
    this.suscriptions.forEach((suscription: Subscription) => suscription.unsubscribe());
  }
}
