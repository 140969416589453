import { Injectable } from '@angular/core';
import { Subscription, firstValueFrom, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { XUserTokenService } from '../token/xUserToken.service';
import { TraderService } from '../trader/trader.service';


@Injectable({
  providedIn: 'root'
})
export class SessionService {

  /* `timerSubscription!: Subscription;` is declaring a property `timerSubscription` of type
  `Subscription` and initializing it with `undefined`. */
  timerSubscription!: Subscription;
  
  /**
   * This is a constructor function that takes in two services as parameters.
   */
  constructor(
    private xUserTokenSer: XUserTokenService,
    private traderSer: TraderService
  ){}
  
  /**
   * This function checks if a user token needs to be refreshed and refreshes it if necessary.
   * @returns The function `startingProcess()` returns nothing (`undefined`). It either awaits the
   * `auth()` method of `traderSer` and returns nothing, or sets up a timer subscription and returns
   * nothing.
   */
  async startingProcess(){
    if(this.xUserTokenSer.get() === ""){
      await firstValueFrom(this.traderSer.auth()).catch(val => {});
      return;
    }

    const currentDate = new Date();
    const refreshDate = this.xUserTokenSer.getDateToRefresh();
    
    if (currentDate >= refreshDate) {
      await firstValueFrom(this.traderSer.auth()).catch(val => {});
      this.stopTimer();
    }else{
      const timeToWait = refreshDate.getTime() - currentDate.getTime();

      this.timerSubscription = timer(timeToWait)
      .pipe(take(1))
      .subscribe(async() => {
        await firstValueFrom(this.traderSer.auth()).catch(val => {});
        this.stopTimer();
      });
    }
  }


  /**
   * This function stops a timer subscription if it exists.
   */
  private stopTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

}