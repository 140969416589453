import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FLUX } from '@src/app/constants/flux.const';
import * as QRCode from 'qrcode';

import { AuthService } from '@app/services/auth.service';
import { Subscription } from 'rxjs';
import { RetornAppUser } from '../../services/auth.service';
import { TranslationService } from '@src/app/services/translate/translation.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})

export class InstructionsComponent {
  @Input() flux!: FLUX;
  
  @ViewChild("canvasQR", { static: true }) canvasQr!: ElementRef;

  
  public _FLUX = FLUX;
  imgQr:any;
  center_image:any;
  private goTo = '';
  private lang = this.translation.currentLang.proxy.lang;
  /* `stepsDigitalize` is an array of objects that represent the steps for the "digitalize" process.
  Each object has three properties: `step`, `description`, and `active`.
  The `step` property is a string that represents the title of the step,
  the `description` property is a string that represents the description of the step,
  and the `active` property is a boolean that indicates whether the step is currently active or not.
  The first step is set to active by default, while the rest are set to inactive. 
  This array is used to display the steps in the instructions component for the "digitalize" process. */
  stepsDigitalize = [
    {
      step: "instructions.digitalize.step_1.title",
      description: 'instructions.digitalize.step_1.description',
      active: true
    },
    {
      step: "instructions.digitalize.step_2.title",
      description: 'instructions.digitalize.step_2.description',
      active: false
    },
    {
      step: "instructions.digitalize.step_3.title",
      description: 'instructions.digitalize.step_3.description',
      active: false
    },
    {
      step: "instructions.digitalize.step_4.title",
      description: 'instructions.digitalize.step_4.description',
      active: false
    }
  ];
  /* `stepsRedeem` is an array of objects that represent the steps for the "redeem" process. Each
  object has three properties: `step`, `description`, and `active`.
  The `step` property is a string that represents the title of the step,
  the `description` property is a string that represents the description of the step,
  and the `active` property is a boolean that indicates whether the step is currently active or not. 
  The first step is set to active by default, while the rest are set to inactive.
  This array is used to display the steps in the instructions component for the "redeem" process. */
  stepsRedeem = [
    {
      step: "instructions.redeem.step_1.title",
      description: 'instructions.redeem.step_1.description',
      active: true
    },
    {
      step: "instructions.redeem.step_2.title",
      description: 'instructions.redeem.step_2.description',
      active: false
    },
    {
      step: "instructions.redeem.step_3.title",
      description: 'instructions.redeem.step_3.description',
      active: false
    },
    {
      step: "instructions.redeem.step_4.title",
      description: 'instructions.redeem.step_4.description',
      active: false
    }
  ];
  fluxToshow: any;
  private suscriptions: Subscription[] = [];
  
  /**
   * This function creates a QR code with a URL that includes a temporary route and user information
   * obtained from the AuthService.
   */
  constructor(
    private readonly translation: TranslationService,
    private translate: TranslateService,
    private authService: AuthService,
  ) {
      const userInfo$ = this.authService.retornAppUser.subscribe(async (response: RetornAppUser | null) => {
        if (response) {
          this.goTo = await this.authService.getMagicLink();
          this.buildQRCode(this.lang);
        }
      });
      this.suscriptions.push(userInfo$);
      /* This code subscribes to the `onLangChange` event of the `translate` service and listens for
      changes in the language. When a language change event occurs, it updates the `dateFormat`
      property of the component based on the new language. The subscription is added to the
      `suscriptions` array to be unsubscribed later in the `ngOnDestroy()` function. */
      const changeLanguaje$ = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        if (this.lang !== event.lang) {
          this.lang = event.lang;
          this.buildQRCode(event.lang);
        }
      });
      this.suscriptions.push(changeLanguaje$);
  }

  /**
   * The ngOnInit function sets the fluxToshow variable based on the flux value and then sets an
   * interval to cycle through the steps, while also setting the center_image variable.
   */
  async ngOnInit() {
    this.fluxToshow = this.flux === this._FLUX.VIRTUALIZE ? this.stepsDigitalize : this.stepsRedeem;
    setInterval(() => {
      var index =   this.fluxToshow.map(function(e:any) {
        return e.active;
      }).indexOf(true);
       let nextId = index < 3 ? index+1 : 0;
      this.fluxToshow[index].active = false;
      this.fluxToshow[nextId].active = true;
    },7000);
    this.center_image = this.flux === this._FLUX.VIRTUALIZE ? "assets/png/qr-virtualizar@3x.svg" : "assets/png/qr-canjear@3x.svg";
  }
  /**
   * This function changes the active state of an item in an array based on the index provided.
   * @param {any} index - The index parameter is a number that represents the index of the element in
   * the array that needs to be set as active.
   */
  changeActive(index:any) {
    this.fluxToshow.forEach((step:any, idx:any) => {
      if (index === idx) {
        this.fluxToshow[idx].active = true;
      } else {
        this.fluxToshow[idx].active = false;
      }
    });
  }
   /**
    * This function creates a QR code as a data URL with specified parameters.
    */
  buildQRCode(lang: string) {
    if(!this.goTo) return;
    const urlWithFlux = `${this.goTo}&temp_route=${this.flux}&temp_lang=${lang}`;
    console.log(urlWithFlux);
    
    this.imgQr = QRCode.toDataURL(urlWithFlux,  {
      errorCorrectionLevel: "Q",
      margin: 1,
      width:266,
      color: {
         dark:"#ff0000",
         light:"#FFE9E7"
       }
    });
  }

  /**
   * The function reloads the current page.
   */
  reloadPage(){
    window.location.reload();
  }
}
