import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { observe } from 'rxjs-observe';

@Injectable({
	providedIn: 'root'
})
export class TranslationService {
  private readonly SUPPORT_LANGUAGES = ['es', 'en', 'pt'];
  private readonly defaultLang = this.SUPPORT_LANGUAGES[0];
	currentLang;

	/**
   * Sets up the language translation service and selects the appropriate language based
   * on the user's preferences or browser settings.
   */
  constructor(
		public translate: TranslateService,
	) {
		translate.addLangs(this.SUPPORT_LANGUAGES);
		translate.setDefaultLang(this.defaultLang);

		const languageSaved = this.getSavedLanguage();
		const language = languageSaved === '' ? this.getBrowserLang() : languageSaved;

    translate.use(language);

		this.currentLang = observe({ lang: this.translate.currentLang });
	}

	/**
   * This function changes the language of the application and saves the selected language.
   * @param {string} language - The `language` parameter is a string that represents the language code
   * for the desired language to be used in the translation. For example, "en" for English, "es" for Spanish.
   */
  async changeLanguage(language: string) {
    if (!this.SUPPORT_LANGUAGES.includes(language)) return;
		this.translate.use(language);
		this.currentLang.proxy.lang = language;
		this.saveLanguage(language);
	}

  /**
   * This function returns the browser language if it is supported, otherwise it returns the default
   * language.
   * @returns String that represents the language code of the user's browser language. 
   * If the browser language is not supported or cannot be detected, it
   * returns the default language code.
   */
  private getBrowserLang(): string {
    const browserLang = this.translate.getBrowserLang();
    if (!browserLang) return this.defaultLang;

    if (this.SUPPORT_LANGUAGES.includes(browserLang)) return browserLang;

    return this.defaultLang;
  }
    
	/**
   * Save the language selected by the user
   * @param tempRoute {string} language id
   */
  private saveLanguage(language: string) {
    try {
      localStorage.setItem('language', language);
    } catch (error) {
      console.error("❌ ~ Access is denied to localStorage.");
    }
  }
  /**
   * Consult if user has selected a lenguaje
   * @returns {string} language id
   */
  private getSavedLanguage(): string {
    try {
      const language = localStorage.getItem('language');
      if (!language) return '';
      if (this.SUPPORT_LANGUAGES.includes(language)) return language;
    } catch (error) {
        console.error("❌ ~ Access is denied to localStorage.");
    }
    return '';
  }

}
