import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

/* ENVIRONMENTS */
import { environment } from '@src/environments/environment';

/* SERVICES */
import { XUserTokenService } from '@app/services/token/xUserToken.service';
import { AccessTokenService } from '@app/services/token/AccessToken.service';
import { AuthService, UserPermission } from '@app/services/auth.service';
import { RetornAppUser } from '../../services/auth.service';
import { log } from 'console';
@Injectable()
export class RequestInterceptor implements HttpInterceptor, OnDestroy {
	public retornAppUser!: RetornAppUser | null;
  	private suscriptions: Subscription[] = [];
	public userPermission!: UserPermission | null;
	private applicationId = '';
	private readonly ROUTES_REQUEST_APP_ID = [
		'/manage/addBatchCustomers',
		'/manage/addCustomer',
		'/manage/updateCustomer?clientId=apim-cocacolaAPI-apim',
		'/manage/getCustomersByBottler?clientId=apim-cocacolaAPI-apim',
		'/manage/getCustomerByProfile?clientId=apim-cocacolaAPI-apim',
		'/manage/requestBlockCustomer',
		'/manage/reactivateCustomer'	
	];

	/**
	 * Creates an instance of RequestInterceptor.
	 * @param {XUserTokenService} xUserTokenSer
	 * @memberof RequestInterceptor
	 */
	constructor(
		private xUserTokenSer: XUserTokenService,
		private AccessTokenService: AccessTokenService,
		private authService: AuthService
	) {
		const userInfo$ = this.authService.retornAppUser.subscribe((response: RetornAppUser | null) => {
			if (response !== null) {
				this.retornAppUser = response;
			}
		});
    	this.suscriptions.push(userInfo$);
		const userPermissions$ = this.authService.getUserPermissions().subscribe((response: UserPermission | null) => {
			this.userPermission = response;

			const app = this.userPermission?.app.find(val => val.applicationName?.toLowerCase() === 'retornapp');
			this.applicationId = app?.applicationId ?? '';
		});
    	this.suscriptions.push(userPermissions$);
	}
  
	/**
	 * This function intercepts HTTP requests and adds a token and base URL if necessary before passing
	 * them to the next handler.
	 * @param req - HttpRequest<any> - This is the HTTP request object that is being intercepted and
	 * modified before being sent to the server.
	 * @param {HttpHandler} next - `next` is an instance of the `HttpHandler` class, which represents the
	 * next interceptor or the backend server that will handle the HTTP request. It is responsible for
	 * forwarding the request to the next interceptor or the backend server and returning the response
	 * back to the previous interceptor.
	 * @returns The `intercept` function is returning an Observable of type `HttpEvent<any>`.
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		
		if(req.method === "GET"){
			if(this.xUserTokenSer.routes.getTokenized().includes(req.url.split("?")[0])){
				req = this.addToken(req);
			}
			if(this.AccessTokenService.routes.getAccessTokenServices().includes(req.url.split("?")[0])){
				req = this.addTokenCMDP(req);
			}
		}else{
			if(this.AccessTokenService.routes.getAccessTokenServices().includes(req.url)){
				req = this.addTokenCMDP(req);
				req = this.addPreferredLanguage(req);
			}

			if(this.xUserTokenSer.routes.getTokenized().includes(req.url)){
				req = this.addToken(req);
				req = this.addPreferredLanguage(req);
			}
		}
		
		if(!['/assets/i18n/es.json', '/assets/i18n/en.json', '/assets/i18n/pt.json'].includes(req.url)) {
			req = this.addBaseUrl(req);
		}
		
		
		return next.handle(req);
	}

	addPreferredLanguage(req: HttpRequest<any>): HttpRequest<any> {
		const language = localStorage.getItem('language') ?? 'en';
		return req.clone({
			setHeaders: {
				'X-API-Language': language,
			}
		});
	}

	/**
	 * This function adds a base URL to an HTTP request.
	 * @param req - HttpRequest<any> is a generic type in Angular's HttpClient module that represents an
	 * HTTP request. It contains information such as the request method (e.g. GET, POST), headers, URL,
	 * and body. In this function, the req parameter is the original HTTP request that needs to be
	 * modified by adding
	 * @returns The function `addBaseUrl` returns an `HttpRequest` object with the URL property modified
	 * to include the base URL from the `environment` object concatenated with the original URL from the
	 * input `req` object.
	 */
	addBaseUrl(req: HttpRequest<any>): HttpRequest<any>{
		return req.clone({ 
			url: `${environment.baseUrl}${req.url}` 
		});
	}
	addTokenCMDP(req: HttpRequest<any>): HttpRequest<any> {
		const AccessToken = this.AccessTokenService.get();
		return req.clone({
			setHeaders: {
				'Authorization': AccessToken
			}
		});
	}
	/**
	 * This function adds headers to an HTTP request based on the request URL and user permissions.
	 * @param req - The `req` parameter is an instance of the `HttpRequest` class.
	 * It represents an HTTP request that is being made by the application.
	 * The `addToken` method is used to add authentication headers to the request before it is sent to the server.
	 * @returns The `addToken` function is returning a modified `HttpRequest` object with additional
	 * headers (`x-user-token` and `x-application-id`) added based on the URL of the original request. If
	 * the URL matches one of the specified conditions, the function returns a cloned request object with
	 * the corresponding headers added.
	 */
	addToken(req: HttpRequest<any>): HttpRequest<any> {
		let xUserToken = this.xUserTokenSer.get();
		if(req.url === "/retornapp/trader/auth"){
			xUserToken = environment.tokenTraderAuth ?? '';
		}

		if(this.ROUTES_REQUEST_APP_ID.includes(req.url)) {
			return req.clone({
				setHeaders: {
					'x-application-id': this.applicationId
				}
			});
		}

		if(req.url === "/reportByBottler"){
			return req.clone({
				setHeaders: {
					'x-user-token': xUserToken,
					'x-application-id': this.applicationId
				}
			});
		}
		
		if(req.url === "/manage/addCustomerBottler"){
			return req.clone({
				setHeaders: {
					'x-user-token': xUserToken,
					'x-application-id': this.applicationId,
					'x-functions-key': environment.function_key
				}
			});
		}

		return req.clone({
			setHeaders: {
				'x-user-token': xUserToken
			}
		});
	}

    /**
	 * The ngOnDestroy function unsubscribes from all subscriptions in the suscriptions array.
	 */
	ngOnDestroy(): void {
        this.suscriptions.forEach((suscription: Subscription) => suscription.unsubscribe());
    }
}
