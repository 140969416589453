import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { APP_STATUS } from '@app/constants/flux.const';
import { AppService } from '@app/services/app.service';

@Injectable({
  providedIn: 'root'
})
export class RetornAppBottlerPrivateGuard implements OnDestroy {
  private suscriptions: Subscription[] = [];
  private appStatus!: APP_STATUS;
  
  constructor(
    private router: Router,
    private readonly appService: AppService
  ) {
    const appStatus$ = this.appService.getAppStatus().subscribe((response: APP_STATUS) => this.appStatus = response);
    this.suscriptions.push(appStatus$);
  }

  /**
   * This function checks if a user can activate a route and redirects them if necessary.
   * @param {ActivatedRouteSnapshot} route - An object that contains information about the route
   * associated with the component that is being activated.
   * @param {RouterStateSnapshot} state - `state` is a snapshot of the current router state, which
   * includes the URL and the parameters of the current route. It is of type `RouterStateSnapshot`.
   * @returns  The returned value indicates whether the user is allowed to activate the route or not. 
   * If the value is `true`, the route can be activated, and if it is `false` or a `UrlTree` object, the route activation
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    const redirectTo = this.evaluateRedirect();
    if (redirectTo) this.router.navigateByUrl(redirectTo);
    return !redirectTo; // If redirectTo is empty, can active 
  }

  /**
   * This function evaluates the redirect URL based on the current app status.
   * @returns The method `evaluateRedirect()` returns a string value that represents the URL path to
   * redirect to based on the current value of the `appStatus` property.
   */
  private evaluateRedirect(): string {
    if (APP_STATUS.RETORN_APP_BOTTLER === this.appStatus) return '';
    if (APP_STATUS.RETORN_APP_TENDERO === this.appStatus) return '/retornapp/home';
    if (APP_STATUS.LANDING_HOME === this.appStatus) return '/portal';
    return '/';
  }

  /**
   * The ngOnDestroy function unsubscribes from all subscriptions in the suscriptions array.
   */
  ngOnDestroy(): void {
    this.suscriptions.forEach((suscription: Subscription) => suscription.unsubscribe());
  }
}
