/* Angular Imports */
import { Injectable } from '@angular/core';

/**
 * This service provides encoding and decoding functionality.
*/
@Injectable({
    providedIn: 'root'
})
export class EncodingService {

    /**
     * Encodes an object to Base64 string.
     * 
     * @param object - The object to encode.
     * @returns The Base64 string representation of the object.
     * @throws If an error occurs during encoding.
     */
    encodeObjectToBase64(object: any): string {
        try {
            const json = JSON.stringify(object);
            const encoder = new TextEncoder();
            const data = encoder.encode(json);
            const base64 = btoa(String.fromCharCode(...data));
            return base64;
        } catch (error) {
            console.error('Error encoding object to Base64:', error);
            throw error;
        }
    }

    /**
     * Encodes an object to Base64 string.
     * 
     * @param object - The object to encode.
     * @returns The Base64 string representation of the object.
     * @throws If an error occurs during encoding.
     */
    decodeObjectFromBase64(base64Data: string): any {
        try {
            const json = atob(base64Data);
            const object = JSON.parse(json);
            return object;
        } catch (error) {
            console.error('Error decoding object from Base64:', error);
            throw error;
        }
    }

}