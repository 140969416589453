import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { AppService } from '@app/services/app.service';
import { Subscription, filter, firstValueFrom } from 'rxjs';
import { APP_STATUS, FLUX } from '@app/constants/flux.const';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SessionService } from './services/session/session.service';
import { MsalService } from '@azure/msal-angular';
import { AuthService, RetornAppUser, UserPermission } from './services/auth.service';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies } from '@src/app/auth-config';
import { environment } from '@src/environments/environment';
import { MsalBroadcastService } from '@azure/msal-angular';
import { TranslationService } from './services/translate/translation.service';
import { log } from 'console';
import { CookieService } from 'ngx-cookie-service';
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  /* The above code is defining several arrays of routes for different parts of a web application. */
  private readonly SHARED_PUBLIC_ROUTES = ['/cookies','/open-api/swagger', '/terms-and-conditions', '/404',];
  private readonly SHARED_PROTECTED_ROUTES = ['/retornapp/account','/logout'];
  private readonly LANDING_ROUTES = ['/', '/aboutus', '/faq', ...this.SHARED_PUBLIC_ROUTES];
  private extras = '/create-user'; // TODO: Delete
  private readonly LANDING_HOME_ROUTES = ['/portal', ...this.SHARED_PUBLIC_ROUTES, ...this.SHARED_PROTECTED_ROUTES, this.extras];
  private readonly RETORNAPP_TENDERO_ROUTES = ['/retornapp/home', '/retornapp/digitalize', '/retornapp/redeem', '/retornapp/history', ...this.SHARED_PUBLIC_ROUTES, ...this.SHARED_PROTECTED_ROUTES];
  private readonly RETORNAPP_BOTTLER_ROUTES = ['/retornapp/bottler', '/retornapp/store', '/retornapp/stores', '/retornapp/load-stores', '/retornapp/guide', ...this.SHARED_PUBLIC_ROUTES, ...this.SHARED_PROTECTED_ROUTES];

  private suscriptions: Subscription[] = [];
  public readonly _APP_STATUS = APP_STATUS;
  public appStatus!: APP_STATUS;
  public disablePaddingBottom = false;
  public isLoading!: boolean;
  private isAuthenticated = false;
  private currentUrl = '';
  public activeCreateUser = false; // TODO: Delete
  public tenant = environment.tenant;
  public clientID = environment.clientID;
  public scopeApi = `https://${this.tenant}.onmicrosoft.com/${this.clientID}/AccessCMDP`;
  private inactivityTimeout = 60 * 60; // Duration in seconds (secondsxminute * minutes) of inactivity before logging off
  private inactivityTimer: any;
  private stopInactivityTimer = false;

  /**
   * This is a constructor function that initializes various services and subscribes to router events
   * to determine the current URL and perform authentication or normal flow based on the URL.
   */
  constructor(
    private authService: MsalService,
    private readonly appService: AppService,
    private sessionSer: SessionService,
    private _route: ActivatedRoute,
    private readonly translation: TranslationService,
    private readonly localAuthService: AuthService,
    private readonly router: Router,
    private cookieService: CookieService,
    private msalBroadcastService: MsalBroadcastService,
    private activateRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {    
   setTimeout(() => {
     // Al cargar la página
  
  if (!sessionStorage.getItem('loaded')) {
    // Es la primera vez que se carga la página      
    if (sessionStorage.getItem('isLogin') === 'true') {
      this.closeSession();
      this.cleanLocalAndSessionStorage();
      sessionStorage.setItem('loaded', 'true');
    }
  
  } else {
    // Es un refresh o un regreso a la página  
  }
   });
    const r$ = this.router.events.subscribe((event:any) => {
      if (event instanceof NavigationEnd) {
        const firstTime = this.currentUrl === '';
        
        this.currentUrl = event.url;
        this.disablePaddingBottom = this.currentUrl.startsWith('/portal');
        this.activeCreateUser = this.currentUrl === '/create-user'; // TODO: DELETE
        
        if (!firstTime) return;
        if (this.currentUrl.startsWith('/auth')) {
          this.authFlow();
          
        } else {
          this.normalFlow();
        }
      }
    });
    this.suscriptions.push(r$);
    
    this.evaluateNoConnection();
    this.reload();
  }
  
  
  /**
   * This function adds event listeners to detect when the wifi connection has been lost or restored
   * and triggers corresponding actions.
   */
  private evaluateNoConnection() {
    if (typeof window === 'undefined') return;
    /* Event listener to detect when wifi connection has been lost */
    window?.addEventListener('offline', () => {
      document?.getElementById('btnOpenNoInternetConectionModal')?.click();
    });

    /* Event listener to detect when wifi connection has been restored */
    window?.addEventListener('online', () => {
      document?.getElementById('btnCloseNoInternetConectionModal')?.click();
    });
  }

  async reload(){
  //   await this.router.events.subscribe((event) => {
  //     console.log('entro a subscribe re deload');
  //     console.log(event);
      
  //     if (event instanceof NavigationStart) {
  //      const  browserRefresh = !this.router.navigated;
  //       console.log('this.router.navigated');
        
  //       if(localStorage.getItem('isLogin') === 'true'){
  //         if(browserRefresh){
  //          console.log('entro a recarga con sesion acitva');
           
  //         }
  //       }
  //     }
  // });
 
  }
  /**
   * The function handles different authentication flows based on query parameters and subscribes to
   * the active route.
   */
  private authFlow() {
    const activeRoute$ = this.activateRoute.queryParams.subscribe((params) => {
      const tokenTransfer = params['token_transfer'];
      const loginPhoneFlag = params['login_phone'];
      const loginMailFlag = params['login_mail'];
      if (tokenTransfer) {
        const tempRoute = params['temp_route'];
        const tempLang = params['temp_lang'];
        this.translation.changeLanguage(tempLang ?? '');
        this.setTemporalRoute(tempRoute ?? '');
        setTimeout(() => this.magicLogin(tokenTransfer), 0);
      } else if (loginPhoneFlag) {
        setTimeout(() => this.sigInWithPhone(), 0);
      } else if (loginMailFlag) {
        setTimeout(() => this.sigInWithMail(), 0);
      } else {
        this.normalFlow();
      }
    });
    this.suscriptions.push(activeRoute$);
  }

  /**
   * This function sets up listeners for various events related to app status, loading, user
   * authentication, and MSAL login.
   */
  private normalFlow() {
    // Listener app status
    const appStatus$ = this.appService.getAppStatus().subscribe((response: APP_STATUS) => this.appStatus = response);
    this.suscriptions.push(appStatus$);

    // Listener app loading
    const isLoading$ = this.appService.isLoading.subscribe((response: boolean) => this.isLoading = response);
    this.suscriptions.push(isLoading$);

    // Listener user authentication
    const isAuthenticated$ = this.localAuthService.isAuthenticated().subscribe(async(response: boolean | null) => {
      if (response !== null) {
        this.isAuthenticated = response
        console.warn("🚀 ~ isAuthenticated:", this.isAuthenticated);
        sessionStorage.setItem('isLogin', 'true');
        if (response === true) {
          await this.userLogged();
        } else {
          this.userNoLogged();
        }
      }
    });
    this.suscriptions.push(isAuthenticated$);

    // Listener msal login
    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        const isLogged = this.authService.instance.getAllAccounts().length > 0;
        if (isLogged) {
          const activeAccount = this.authService.instance.getActiveAccount();
          this.localAuthService.login(activeAccount);
        } else {
          //this.localAuthService.logout();
          this.appService.isLoading.next(false);
        }
      });
  }

  /**
   * Actions to init app when user is not logged
   */
  private userNoLogged() {
    this.saveStatusAndRedirect(APP_STATUS.LANDING, [...this.LANDING_ROUTES], '/')
  }
  /**
   * Actions to init app when user is logged
   */
  private async userLogged() {
    this.initInactivityTimer();
    const userPermission: UserPermission | null = await firstValueFrom(this.localAuthService.consumeUserPermissions(this.localAuthService.activeAccount));
    if (!userPermission) this.closeSession();

   const hasAccessToRetornApp = userPermission?.app.find((val) => /retornapp/gi.test(val?.applicationName?.toLocaleLowerCase() ?? ''));
    if (hasAccessToRetornApp) {      
      // Consume the profile from retornapp user
      const retornAppUser: RetornAppUser | null = await firstValueFrom(this.localAuthService.consumeCustomerByProfile(this.localAuthService.activeAccount));
      if (!retornAppUser) this.closeSession();

      // Trader auth to tendero and bottler
      await this.sessionSer.startingProcess();
      
      const isBottler = retornAppUser?.Customer_customerType === 'BOTTLER';
      
      if (isBottler) {
        this.bottlerSettings();
      } else {
        await this.tenderoSettings();
      }
    } else {
      this.saveStatusAndRedirect(APP_STATUS.LANDING_HOME, this.LANDING_HOME_ROUTES, '/portal');
    }
    this.appService.isLoading.next(false);
  }

  /**
   * Initialize app configuration for user with access to retornapp and role is bottler
   */
  private bottlerSettings() {
    const selectedFromLoggedScope = [APP_STATUS.LANDING_HOME, APP_STATUS.RETORN_APP_BOTTLER].includes(this.appStatus);        
    if (!selectedFromLoggedScope) {
      this.saveStatusAndRedirect(APP_STATUS.LANDING_HOME, [...this.RETORNAPP_BOTTLER_ROUTES, ...this.LANDING_HOME_ROUTES], '/portal');
    }
 }

 /**
  * Initialize app configuration for user with access to retornapp and role is tendero
  */
  private async tenderoSettings() {
    if (this.hasTemporalRouter()) return;    
    const selectedFromLoggedScope = [APP_STATUS.LANDING_HOME, APP_STATUS.RETORN_APP_TENDERO].includes(this.appStatus);
    if (!selectedFromLoggedScope) {
      this.saveStatusAndRedirect(APP_STATUS.LANDING_HOME, [...this.RETORNAPP_TENDERO_ROUTES, ...this.LANDING_HOME_ROUTES], '/portal');
    }
  }

  /**
   * Save status and redirect if the currect routes no match with the correct routes
   * @param status {APP_STATUS} new status to set
   * @param correctRoutes {string[]} routes to allow in the new status
   * @param correctRedirect {string} route to redirect if is active a diferent route from the correctRoutes 
   */
  private saveStatusAndRedirect(status: APP_STATUS, correctRoutes: string[], correctRedirect: string,) {
    this.appService.setAppStatus(status);
    if (!correctRoutes.includes(this.currentUrl)) this.router.navigateByUrl(correctRedirect);
  }
  
  /**
   * Save temporal route for redirect after login 
   * @param tempRoute {string}: route to save
   */
  private setTemporalRoute(tempRoute: string) {
    try {
      localStorage.setItem('temp_route', tempRoute);
    } catch (error) {
      console.error("❌ ~ Access is denied to localStorage.");
    }
  }
  /**
   * Evaluate case with user has temporal routes to redirect
   * @returns {boolean} true if has routes
   */
  private hasTemporalRouter(): boolean {
    try {
      const tempRoute = localStorage.getItem('temp_route');
      if (!tempRoute) return false;
      localStorage.removeItem('temp_route');
      if ([FLUX.REDEEM.toString(), FLUX.VIRTUALIZE.toString()].includes(tempRoute)) {
        this.appService.setAppStatus(APP_STATUS.RETORN_APP_TENDERO);
        this.router.navigateByUrl(`/${tempRoute}`);
        console.log('temproute',tempRoute);
        
        return true;
      };
    } catch (error) {
        console.error("❌ ~ Access is denied to localStorage.");
    }
    return false;
  }

  /**
   * This function checks if there is an active account and sets the first account as active if there
   * are multiple accounts signed in.
   */
  private checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      // add your code for handling multiple accounts here
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  /**
   * This function performs a magic login using a token transfer and redirects the user to the
   * sign-up/sign-in page.
   * @param {string} tokenTransfer - The `tokenTransfer` parameter is a string that represents an ID
   * token that is used as a hint to the identity provider during the authentication process.
   */
  private async magicLogin(tokenTransfer: string) {
    await firstValueFrom(this.authService.initialize());
    const authRequestConfig: RedirectRequest = {
      authority: b2cPolicies.authorities.signUpSignInMagicLynk.authority,
      scopes: [this.scopeApi],
      extraQueryParameters: {"ui_locales": this.translation.currentLang.proxy.lang.toUpperCase(), "id_token_hint": tokenTransfer}
    };
    this.authService.loginRedirect({ ...authRequestConfig, scopes: [this.scopeApi] });
  }

  /**
   * This function initializes authentication and redirects the user to sign in with their phone number.
   */
  private async sigInWithPhone() {
    await firstValueFrom(this.authService.initialize());
    const authRequestConfig: RedirectRequest = {
      authority: b2cPolicies.authorities.signInWithPhone.authority,
      scopes: [],
      extraQueryParameters: {"ui_locales" :this.translation.currentLang.proxy.lang.toUpperCase()}
    };
    this.authService.loginRedirect({ ...authRequestConfig, scopes: [this.scopeApi] });
  }

  /**
   * This function initializes authentication and redirects the user to a sign-up/sign-in page with mail
   */
  private async sigInWithMail() {
    await firstValueFrom(this.authService.initialize());
    const authRequestConfig: RedirectRequest = {
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      scopes: [],
      extraQueryParameters: {"ui_locales" :this.translation.currentLang.proxy.lang.toUpperCase()}
    };
    this.authService.loginRedirect({ ...authRequestConfig, scopes: [this.scopeApi] });
  }

  /**
   * This function calls the detectChanges method of the cdRef object.
   */
  public detectChanges() {
    this.cdRef.detectChanges();
  }

  /**
   * The function initializes an inactivity timer by resetting it whenever there is mouse movement or a
   * keypress event.
   */
  private initInactivityTimer(): void {
    if (typeof window === 'undefined') return;

    this.resetInactivityTimer();

    window.addEventListener('mousemove', this.resetInactivityTimer.bind(this));
    window.addEventListener('keypress', this.resetInactivityTimer.bind(this));
  }

  /**
   * The function resets an inactivity timer and triggers a modal when the timer expires.
   */
  private resetInactivityTimer(): void {
    if (this.stopInactivityTimer === true) return;

    clearTimeout(this.inactivityTimer);

    this.inactivityTimer = setTimeout(() => {
      this.stopInactivityTimer = true;
      this.cleanLocalAndSessionStorage();
      document?.getElementById('btnOpenExpiredSessionModal')?.click();
    }, this.inactivityTimeout * 1000);
  }

  /**
   * The function "cleanLocalAndSessionStorage" clears the sessionStorage and localStorage,
   * and then reset the language.
   */
  public cleanLocalAndSessionStorage() {
    sessionStorage.clear();
    localStorage.clear();

    this.translation.changeLanguage(this.translation.currentLang.proxy.lang);
  }

  /**
   * Close local and msal session
   */
  public async closeSession() {
    this.appService.isLoading.next(true);
    this.authService.logout();
  }
ngOnInit() {
 
}
  /**
   * The ngOnDestroy function sets the app status to landing and unsubscribes from all subscriptions.
   */
  ngOnDestroy(): void {
    this.appService.setAppStatus(APP_STATUS.LANDING);
    this.suscriptions.forEach((suscription: Subscription) => suscription.unsubscribe());
  }

}
