import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APP_STATUS } from '@app/constants/flux.const';

@Injectable({
  providedIn: 'root'
})
export class AppService {
    /* value of email to print in all page. */
    public readonly email = 'support@coca-cola.com';
    /* holds the current status of the application. */
    private appStatus: BehaviorSubject<APP_STATUS> = new BehaviorSubject<APP_STATUS>(APP_STATUS.LANDING);
    /* holds the loading status. */
    public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    
    /**
     * This is a constructor function that initializes the app status by evaluating it.
     */
    constructor() {
        this.appStatus.next(this.evaluateAppStatus());
    }

    /**
     * This function evaluates the current status of the app by checking the value stored in
     * localStorage and returning the appropriate APP_STATUS enum value.
     * @returns an APP_STATUS enum value based on the value stored in the 'status' key of the
     * localStorage object.
     */
    private evaluateAppStatus(): APP_STATUS {
        const defaultStatus = APP_STATUS.LANDING;
        try {
            const status = localStorage.getItem('status');
            if (!status) return defaultStatus;

            if (APP_STATUS.RETORN_APP_TENDERO.toString() === status) return APP_STATUS.RETORN_APP_TENDERO;
            if (APP_STATUS.RETORN_APP_BOTTLER.toString() === status) return APP_STATUS.RETORN_APP_BOTTLER;
            if (APP_STATUS.LANDING_HOME.toString() === status) return APP_STATUS.LANDING_HOME;
            return defaultStatus;
        } catch (error) {
            return defaultStatus;
        }
    }
    /**
     * This function returns an observable of the current app status.
     * @returns An Observable of type `APP_STATUS` is being returned.
     */
    public getAppStatus(): Observable<APP_STATUS> {
        return this.appStatus.asObservable();
    }
    /**
     * This function sets the application status and saves it to local storage if possible.
     * @param {APP_STATUS} status - status is a parameter of type APP_STATUS which is being passed to
     * the setAppStatus() function.
     */
    public setAppStatus(status: APP_STATUS) {
        this.appStatus.next(status);

        try {
            localStorage.setItem("status", status.toString());
        } catch (error) {
            console.error("❌ ~ Access is denied to localStorage.");
        }
    }

}