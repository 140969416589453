export const QR_KEYS_AND_TYPES_VIRTUALIZE: [string, string][] = [
    ["hashedKocid", "string"]
];

export const QR_KEYS_AND_TYPES_REDEEM: [string, string][] = [
    ["eventCode", "string"],
    ["hashedKocid", "string"],
    ["quantity", "number"]
];

export const REGEX_FOR_THE_hashedKocid = /^[0-9a-zA-Z]+$/;

export const ERROR_CODES = {
    LIMITS: [
        "EXCEEDED_STORE_REDEEM_DAILY_LIMIT",
        "EXCEEDED_VIRTUALIZE_DAILY_LIMIT",
        "EXCEEDED_BOTTLES_TOTAL_LIMIT"
    ]
}
export const MONTHS_SPANISH = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];
export const MONTHS_ENGLISH = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
export const MONTHS_PORTUGUES = ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"];

export const WORDS_ACTIVITIES = {
    spanish: {
        digitalized: 'Digitalizados',
        redeem: 'Canjeados'
    },
    english: {
        digitalized: 'Digitized',
        redeem: 'Redeem'
    },
    portugues: {
        digitalized: 'Digitalizado',
        redeem: 'Redimido'
    }
}

export interface IgetStore {
    CustomerEstablishment_coorX?: string,
    CustomerEstablishment_coorY?: string,
    CustomerEstablishment_establishmentTypeName?: string,
    CustomerEstablishment_gmLink?: string,
    CustomerEstablishment_id?: string,
    CustomerEstablishment_name?: string,
    CustomerEstablishment_route?: string,
    CustomerExtendedAtribute_BotellasIniciadas?: string,
    CustomerExtendedAtribute_DateInit?:string,
    CustomerProfile_Status?: string,
    CustomerProfile_rol?: string,
    CustomerProfile_traderId?: string,
    Customer_objectId?: string,
    Customer_bottlerCompanyCountry?: string,
    Customer_bottlerCompanyName?: string,
    Customer_bottlerCompanyNsrId?: string,
    Customer_city?: string,
    Customer_clusterLevel?: string,
    Customer_country?: string,
    Customer_createdAt?: string,
    Customer_updatedAt?: string,
    Customer_customerType?: string,
    Customer_displayName?: string,
    Customer_district?: string,
    Customer_emailAddress?:string,
    Customer_externalId?: string,
    Customer_firstName?: string,
    Customer_lastName?: string,
    Customer_middleName?: string,
    Customer_phoneNumber?: string,
    Customer_postalCode?: string,
    Customer_state?: string,
    Customer_streetAddress?: string,
    Customer_tin?: string,

    CustomerExtendedAtribute_DailyDigitalizeLimit?: string,
    CustomerExtendedAtribute_DailyRedeenLimit?: string,
};
export interface IStore {
    Customer_objectId?:string,//id unico de tienda
    CustomerUser_externalId?: string,//campo id de form
    CustomerUser_displayName?: null | string,//mandar vacio
    CustomerUser_firstName?: string,//nombre requred
    CustomerUser_middleName?: string,//primer apellido required
    CustomerUser_lastName?: string,//segundo apellido opcional
    CustomerUser_tin?: null|string,//mandar vacio
    CustomerUser_streetAddress?: string,// mandar direccion
    CustomerUser_district?: null|string,//mandar vacio
    CustomerUser_city?: string,//mandar comuna
    CustomerUser_state?: string, //mandar region
    CustomerUser_postalCode?: string,
    CustomerUser_phoneNumber?: string,
    CustomerUser_emailAddress?: string, // concatenar numeros interior y exterior si existen
    CustomerUser_bottlerCompany?: string, // bottler id cachar de getinfo
    CustomerUser_clusterLevel?: string, // mandar vacio
    CustomerUser_country?: string, // cachar de get info --- y enviar esa misma informacion
    CustomerProfile_traderId?: string,
    Establishment_name?:string,//nombre de tienda
    Establishment_establishmentType?:string,
    CustomerExtendedAtribute_DateInit?:string

    CustomerExtendedAtribute_DailyDigitalizeLimit?: string,
    CustomerExtendedAtribute_DailyRedeenLimit?: string,
};

export interface IUpdateStore {
    CustomerUser_objectId?: string,
    CustomerUser_externalId?: string,
    CustomerUser_displayName?: string,
    CustomerUser_firstName?: string,
    CustomerUser_middleName?: string,
    CustomerUser_lastName?: string,
    CustomerUser_tin?: string,
    CustomerUser_streetAddress?: string,
    CustomerUser_district?: string,
    CustomerUser_city?: string,
    CustomerUser_state?: string,
    CustomerUser_postalCode?: string,
    CustomerUser_phoneNumber?: string,
    CustomerUser_emailAddress?:string,
    CustomerUser_bottlerCompanyName?: string,
    CustomerUser_clusterLevel?: string,
    CustomerUser_country?: string,
    CustomerProfile_traderId?:string,
    CustomerEstablishment_id?: string,
    CustomerEstablishment_name?:string,
    CustomerEstablishment_route?: string,
    CustomerEstablishment_coorX?: string,
    CustomerEstablishment_coorY?: string,
    CustomerEstablishment_gmLink?: string,
    CustomerEstablishment_establishmentTypeName?: string,
    CustomerExtendedAtribute_DateInit?: string,
    CustomerExtendedAtribute_BotellasIniciadas?: string

    CustomerExtendedAtribute_DailyDigitalizeLimit?: string,
    CustomerExtendedAtribute_DailyRedeenLimit?: string,
};

export interface ReportByTendero {
    CustomerEstablishment: string,
    totalRedeemed: number,
    totalVirtualized: number,
}
export interface ReportByBottler {
    name: string;
    result?: ReportByTendero[]
}