<div class="position-fixed h-100 nav-retornapp" *ngIf="[_APP_STATUS.RETORN_APP_BOTTLER, _APP_STATUS.RETORN_APP_TENDERO].includes(appStatus); else landingNavTemplate">
  <div class="collapse collapse-horizontal h-100" id="collapseNavFromHeader"
  >
    <ul class="nav flex-column h-100">
      <li *ngFor="let item of menu" class="nav-item">
        <a  *ngIf="item.redirectTo !== 'portal'; else menuPortalRetornapp"
          class="nav-link "
          [routerLink]="item.redirectTo"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          aria-current="page"
          data-bs-toggle="collapse"
          data-bs-target=".collapse.show"
          aria-label="Toggle menu"
        >
          <span  [class]="'icon-' + item.icon + ' icon'"></span>
          <span>{{ item.title | translate }}</span>
        </a>
        <ng-template #menuPortalRetornapp>
          <a
          class="nav-link "
          aria-current="page"
          data-bs-toggle="collapse"
          data-bs-target=".collapse.show"
          aria-label="Toggle menu"
          (click)="redirToPortal()"
        >
          <span  [class]="'icon-' + item.icon + ' icon'"></span>
          <span>{{ item.title | translate }}</span>
        </a>
        </ng-template>
      </li>

      <li class="nav-item d-md-none">
        <!-- ? ACCOUNT -->
        <a
          class="nav-link text-white ff-unity-headline-bold fs16-lh24"
          aria-current="page"
          data-bs-toggle="collapse"
          data-bs-target=".collapse.show"
          aria-label="Toggle menu"
          [routerLink]="'/retornapp/account'"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          aria-label="Go to account"
        >
          <span class="icon-profile3x icon">
            <span class="path1"></span><span class="path2"></span
            ><span class="path3"></span><span class="path4"></span
            ><span class="path5"></span><span class="path6"></span>
          </span>
          <span>{{ "header.option.account" | translate }}</span>
        </a>
      </li>
      <li class="nav-item padding-nav-link d-md-none" (click)="('')">
        <!-- ? LENGUAJE -->
        <span class="icon-language3x icon"></span>
        <ng-container [ngTemplateOutlet]="lenguajeSelector"></ng-container>
      </li>
      <li class="nav-item d-md-none">
        <!-- ? EXIT -->
        <a
          class="nav-link"
          aria-label="Close session"
          (click)="logout()"
        >
          <span class="icon-Logout3x icon"></span>
          <span>{{ "header.option.exit" | translate }}</span>
        </a>
      </li>
    </ul>
  </div>
  
</div>

<ng-template #lenguajeSelector>
    <div class="dropdown dropend d-inline-block" id="lenguaje-nav">
      <button
        class="btn p-0 border-0 dropdown-toggle ff-unity-headline-bold fs16-lh24 color-white"
        style="vertical-align: top"
        type="button"
        id="dropdownLenguagesMobile"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        #btnLenguajeSelectorMobile
      >
        {{ translation.currentLang.proxy.lang.toLocaleUpperCase() }}
      </button>
      <ul class="dropdown-menu ms-3" aria-labelledby="dropdownLenguagesMobile">
        <li *ngFor="let lenguaje of languages" class="position-relative">
          <button
            class="dropdown-item ff-unity-headline-regular fs16-lh24"
            type="button"
            (click)="translation.changeLanguage(lenguaje[0])"
            [class.color-red-dark]="
              translation.currentLang.proxy.lang === lenguaje[0]
            "
          >
            {{ lenguaje[1] | translate }}
          </button>
        </li>
      </ul>
    </div>
</ng-template>

<ng-template #landingNavTemplate>
  <div class="position-fixed h-100 nav-landing">
    <div class="collapse collapse-horizontal h-100" id="collapseNavFromHeader">
      <ul class="nav flex-column pt-4 h-100">
        <ng-container *ngIf="appStatus === _APP_STATUS.LANDING; else isLoginTemplate">
          <li *ngFor="let item of menuLanding" class="nav-item">
            <a
              *ngIf="item[0] === 'login'; else otherMenuItemTemplate"
              class="nav-link text-white ff-unity-headline-regular fs16-lh24 d-none d-md-block"
              aria-label="Go to login"
              (click)="login()"
              >{{ item[1] | translate }}</a
            >
            <ng-template #otherMenuItemTemplate>
              <a
                class="nav-link text-white ff-unity-headline-regular fs16-lh24"
                aria-current="page"
                [routerLink]="item[0]"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                data-bs-toggle="collapse"
                data-bs-target=".collapse.show"
                >{{ item[1] | translate }}</a
              >
            </ng-template>
          </li>
  
          <!-- ? Collapse lenguaje selector -->
          <li class="nav-item padding-nav-link d-block d-md-none">
            <ng-container [ngTemplateOutlet]="lenguajeSelector"></ng-container>
          </li>
  
          <!-- ? LOGIN -->
          <li class="nav-item padding-nav-link d-block d-md-none">
            <a
              class="color-white ff-unity-headline-medium fs16-lh24 text-decoration-underline"
              aria-label="Go to login"
              (click)="login()"
              >{{ "landing.header.menu.login" | translate }}</a
            >
          </li>
        </ng-container>
  
        <!-- ? HOME PORTAL template -->
        <ng-template #isLoginTemplate>
          <li class="nav-item">
            <!-- ? HOME -->
            <a
              class="nav-link text-white ff-unity-headline-regular fs16-lh24"
              aria-current="page"
              [routerLink]="'/portal'"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              data-bs-toggle="collapse"
              data-bs-target=".collapse.show"
              aria-label="Go to home"
            >
              <span class="icon-Home3x fz-1rem me-2"></span>
              <span>{{ "landing.header.menu.home" | translate }}</span>
            </a>
          </li>
          <li class="nav-item">
            <!-- ? ACCOUNT -->
            <a
              class="nav-link text-white ff-unity-headline-regular fs16-lh24"
              aria-current="page"
              [routerLink]="'/retornapp/account'"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              data-bs-toggle="collapse"
              data-bs-target=".collapse.show"
              aria-label="Go to account"
            >
              <span class="icon-profile3x fz-1rem me-2">
                <span class="path1"></span><span class="path2"></span
                ><span class="path3"></span><span class="path4"></span
                ><span class="path5"></span><span class="path6"></span>
              </span>
              <span>{{ "landing.header.option.account" | translate }}</span>
            </a>
          </li>
          <li class="nav-item padding-nav-link" (click)="('')">
            <!-- ? LENGUAJE -->
            <span class="icon-language3x fz-1rem me-2"></span>
            <ng-container [ngTemplateOutlet]="lenguajeSelector"></ng-container>
          </li>
          <li class="nav-item">
            <!-- ? EXIT -->
            <a
              class="nav-link"
              aria-current="page"
              data-bs-toggle="collapse"
              data-bs-target=".collapse.show"
              aria-label="Close session"
              (click)="logout()"
            >
              <span class="icon-Logout3x fz-1rem me-2"></span>
              <span>{{ "header.option.exit" | translate }}</span>
            </a>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
  
</ng-template>