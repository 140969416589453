import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { APP_STATUS } from '@app/constants/flux.const';
import { AppService } from '@app/services/app.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerPortalPrivateGuard implements OnDestroy {
  private suscriptions: Subscription[] = [];
  private appStatus!: APP_STATUS;
  
  constructor(
    private router: Router,
    private readonly appService: AppService
  ) {
    const appStatus$ = this.appService.getAppStatus().subscribe((response: APP_STATUS) => this.appStatus = response);
    this.suscriptions.push(appStatus$);
  }

  /**
   * This function checks if a child route can be activated and redirects if necessary.
   * @param {ActivatedRouteSnapshot} route - An object that contains information about the route
   * associated with the component that is about to be activated.
   * @param {RouterStateSnapshot} state - `state` is a `RouterStateSnapshot` object that represents the
   * current state of the router.
   * @returns The returned value indicates whether the user is allowed to activate the route or not. 
   * If the value is `true`, the route can be activated, and if it is `false` or a `UrlTree` object, the route activation
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
      const redirectTo = this.evaluateRedirect();
      if (redirectTo) this.router.navigateByUrl(redirectTo);
      return !redirectTo; // If redirectTo is empty, can active child 
  }

  /**
   * This function evaluates the current app status and returns a redirect path based on the status.
   * @returns The method `evaluateRedirect()` returns a string that represents the URL path to redirect
   * to based on the current value of the `appStatus` property.
   */
  private evaluateRedirect(): string {
    if (APP_STATUS.LANDING_HOME === this.appStatus) return '';
    if (APP_STATUS.RETORN_APP_TENDERO === this.appStatus) return '/retornapp/home';
    if (APP_STATUS.RETORN_APP_BOTTLER === this.appStatus) return '/retornapp/bottler';
    return '/';
  }

  /**
   * The ngOnDestroy function unsubscribes from all subscriptions in the suscriptions array.
   */
  ngOnDestroy(): void {
    this.suscriptions.forEach((suscription: Subscription) => suscription.unsubscribe());
  }
}
