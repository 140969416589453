import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { APP_STATUS } from '@app/constants/flux.const';
import { AppService } from '@app/services/app.service';

@Injectable({
  providedIn: 'root'
})
export class PublicGuard implements OnDestroy {
  private suscriptions: Subscription[] = [];
  private appStatus!: APP_STATUS;
  
  constructor(
    private router: Router,
    private readonly appService: AppService
  ) {
    const appStatus$ = this.appService.getAppStatus().subscribe((response: APP_STATUS) => this.appStatus = response);
    this.suscriptions.push(appStatus$);
  }

  
  /**
   * This function checks if a user can activate a route and redirects them if necessary.
   * @param {ActivatedRouteSnapshot} route - An object that contains information about the route
   * associated with the component that is being activated.
   * @param {RouterStateSnapshot} state - `state` is a snapshot of the current router state, which
   * includes the URL and the parameters of the current route. It is of type `RouterStateSnapshot`.
   * @returns The returned value indicates whether the user is allowed to activate the route or not. 
   * If the value is `true`, the route can be activated, and if it is `false` or a `UrlTree` object, the route activation
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    const redirectTo = this.evaluateRedirect();
    if (redirectTo) this.router.navigateByUrl(redirectTo);
    return !redirectTo; // If redirectTo is empty, can active 
  }

  /**
   * The function evaluates the app status and returns a redirect path based on the status.
   * @returns A string representing the URL path to redirect to based on the current value of
   * `appStatus`.
   */
  private evaluateRedirect(): string {
    return this.appStatus === APP_STATUS.LANDING_HOME ? '/portal' :
    this.appStatus === APP_STATUS.RETORN_APP_TENDERO ? '/retornapp/home' : 
    this.appStatus === APP_STATUS.RETORN_APP_BOTTLER ? '/retornapp/bottler' : '';
  }

  /**
   * The ngOnDestroy function unsubscribes from all subscriptions in the suscriptions array.
   */
  ngOnDestroy(): void {
    this.suscriptions.forEach((suscription: Subscription) => suscription.unsubscribe());
  }
}
