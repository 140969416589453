import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class XUserTokenService {

  /* tokenized route */
  private tokenizedRoute: string[] = [];

  /* 10 seconds */
  private criticalTimeToRefresh = 10 * 60 * 1000;

  /* Defining an object called `routes` with two methods: `pushToTokenized` and `getTokenized`. */
  routes = {
    pushToTokenized: (service: string): void => {
      if (!this.tokenizedRoute.includes(service)) {
        this.tokenizedRoute.push(service);
      }
    },
    getTokenized: ():string[] => {
      return this.tokenizedRoute;
    }
  }

  /**
   * Creates an instance of XUserTokenService.
   * @memberof XUserTokenService
   */
  constructor() {}

  /**
   * This function sets a key-value pair in the session storage with the key 'x-user-token'.
   * @param {string} key - The `key` parameter is a string that represents the name of the key to be
   * set in the session storage. In this case, the key is set to `'x-user-token'`.
   */
  set(key: string): void {
    sessionStorage.setItem('x-user-token', key);
  }

  /**
   * This function retrieves a user token from the session storage or returns an empty string if it is
   * not found.
   * @returns The code is returning a string value that is retrieved from the sessionStorage with the
   * key 'x-user-token'. If the value is null or undefined, it returns an empty string.
   */
  get(): string {
    return sessionStorage.getItem('x-user-token') ?? '';
  }
  
  /**
   * This function clears the user token from the session storage.
   */
  clear(): void {
    sessionStorage.removeItem('x-user-token')
  }

  /**
   * This function returns the expiration time of a JSON Web Token (JWT) in milliseconds.
   * @returns the expiration time of a JSON Web Token (JWT) in milliseconds. It does this by decoding
   * the JWT and extracting the "exp" (expiration) claim from the payload, which is a Unix timestamp
   * representing the expiration time in seconds since the Unix epoch. The function then multiplies
   * this value by 1000 to convert it to milliseconds and returns it.
   */
  getExpirationTime(): number {
    return (JSON.parse(atob(this.get().split('.')[1]))).exp  * 1000;
  }

  /**
   * This function returns a Date object representing the time when the data needs to be refreshed
   * based on the expiration time and critical time to refresh.
   * @returns A Date object is being returned. The value of the Date object is calculated by
   * subtracting the criticalTimeToRefresh value from the expiration time obtained from the
   * getExpirationTime() method.
   */
  getDateToRefresh(): Date {
    return new Date(this.getExpirationTime() - this.criticalTimeToRefresh);
  }

}
