import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/* INTERCEPTORS */
import { RequestInterceptor } from './request/request.interceptor';
import { ResponseInterceptor } from './response/response.interceptor';

/* SERVICES */
import { XUserTokenService } from '../services/token/xUserToken.service';

/**
 * Items for interceptors
 */
@NgModule({
	providers: [
		XUserTokenService,
		{ provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true }
	]
})
export class InterceptorsModule {}
