import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from '@src/environments/environment';

const tenant = environment.tenant;
const URL_B2C = environment.URL_B2C;
const clientID = environment.clientID;
const signUpSignIn = environment.signUpSignIn;
const signInWithPhone = environment.signInWithPhone;
const signUpSignInMagicLynk = environment.signUpSignInMagicLynk;
const changePassword = environment.changePassword;
const editProfile = environment.editProfile;
const scopeAccess = `https://${tenant}.onmicrosoft.com/${clientID}/AccessCMDP`;
const scopeUserRead = 'user.read';


let isIE:any;
if (typeof window !== 'undefined') {
    isIE = window?.navigator.userAgent.indexOf("MSIE ") > -1 || window?.navigator.userAgent.indexOf("Trident/") > -1;
}

export const b2cPolicies = {
    names: {
        signUpSignIn,
        signUpSignInMagicLynk,
        changePassword,
        editProfile
    },
    authorities: {
        signUpSignIn: {
            authority: `${URL_B2C}/${tenant}.onmicrosoft.com/${signUpSignIn}`,
        },
        signUpSignInMagicLynk: {
            authority: `${URL_B2C}/${tenant}.onmicrosoft.com/${signUpSignInMagicLynk}`,
        },
        signInWithPhone: {
            authority: `${URL_B2C}/${tenant}.onmicrosoft.com/${signInWithPhone}`,
        },
        changePassword: {
            authority: `${URL_B2C}/${tenant}.onmicrosoft.com/${changePassword}`,
        },
        editProfile: {
            authority: `${URL_B2C}/${tenant}.onmicrosoft.com/${editProfile}`,
        }
    },
    authorityDomain: `${URL_B2C}`
};
 
/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
const redirect = "/auth";
 export const msalConfig: Configuration = {
    auth: {
        clientId: clientID, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: redirect, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri:'/',
        navigateToLoginRequestUrl:false
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

/**
* Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
*/
export const protectedResources = {
 api: {
   endpoint: "https://customerapi-dev.coca-cola.com",
   scopes: [scopeAccess],
   extraScopesToConsent: [scopeAccess],
 },
}


/**
* Scopes you add here will be prompted for user consent during sign-in.
* By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
* For more information about OIDC scopes, visit: 
* https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
*/
export const loginRequest = {
    scopes: [],
    extraQueryParameters: {"ui_locales" : "ES"}
}