
/* This code is defining an enum named `FLUX` and exporting it for use in other modules. The enum has
two members: `VIRTUALIZE` and `REDEEM`. The values of these members are set to `'digitalize'` and
`'redeem'` respectively. This enum can be used to represent different states or actions in a
program. */
export enum FLUX {
    VIRTUALIZE = 'retornapp/digitalize',
    REDEEM = 'retornapp/redeem'
}

/* This code is defining an enum named `APP_STATUS` and exporting it for use in other modules. The enum
has four members: `LANDING`, `LANDING_HOME`, `RETORN_APP_TENDERO`, and `RETORN_APP_BOTTLER`. By
default, the values of these members are set to 0, 1, 2, and 3 respectively. This enum can be used
to represent different states or statuses in a program. */
export enum APP_STATUS {
    LANDING,
    LANDING_HOME,
    RETORN_APP_TENDERO,
    RETORN_APP_BOTTLER
}
