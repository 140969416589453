import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

/* SERVICES */
import { XUserTokenService } from '../token/xUserToken.service';
import { AccessTokenService } from '../token/AccessToken.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  /**
   * Creates an instance of HttpService.
   * @param {HttpClient} httpClient
   * @param {XUserTokenService} xUserTokenSer
   */
  constructor(
    private httpClient: HttpClient,
    private xUserTokenSer: XUserTokenService,
    private AccessTokenService: AccessTokenService
  ) { }

  /**
   * This function sends a POST request to a specified service with a given body and XUserToken, and
   * returns an Observable that either taps into the response or catches any errors.
   * @param {string} service - The URL or endpoint of the API service that the HTTP POST request will
   * be sent to.
   * @param {any} body - The `body` parameter is of type `any` and represents the data that will be
   * sent in the request body of the HTTP POST request. It can be any valid JSON object or any other
   * data type that can be serialized to JSON.
   * @param {Boolean} XUserToken - XUserToken is a boolean parameter that indicates whether the request
   * requires an X-User-Token header to be added to the HTTP request. If XUserToken is true, the method
   * adds the X-User-Token header to the request by calling the pushToTokenized method of the xUserTokenSer
   * @returns an Observable of type `any`.
   */
  post(
    service: string,
    body: any,
    XUserToken: Boolean,
    AccessTokenCMDP?: Boolean
  ): Observable<any> {
    if (XUserToken) {
      this.xUserTokenSer.routes.pushToTokenized(service);
    }
    if (AccessTokenCMDP) {
      this.AccessTokenService.routes.pushAccessToken(service);
    }

    return this.httpClient.post<Response>(service, body).pipe(
      tap(responseOK => responseOK),
      catchError(this.GenericCatchError)
    );
  }

  /**
   * This is a TypeScript function that takes in a service, query, and XUserToken as parameters, and
   * returns an Observable that makes an HTTP GET request with the given parameters.
   * @param {string} service - A string representing the URL of the API endpoint to be called.
   * @param {any} query - The `query` parameter is an object that contains the query parameters to be
   * appended to the URL. The function loops through the object and appends each key-value pair to the
   * URL as a query parameter.
   * @param {Boolean} XUserToken - XUserToken is a boolean parameter that indicates whether the request
   * requires an X-User-Token header to be added to the HTTP request. If XUserToken is true, the method
   * adds the X-User-Token header to the request by calling the pushToTokenized method of the
   * xUserTokenSer
   * @returns An Observable of type `any` is being returned.
   */
  get(
    service: string,
    query: any,
    XUserToken: Boolean,
    AccessTokenCMDP?: Boolean
  ): Observable<any> {
    if (XUserToken) {
      this.xUserTokenSer.routes.pushToTokenized(service);
    }
    if (AccessTokenCMDP) {
      this.AccessTokenService.routes.pushAccessToken(service);
    }
    let queryToSet = "?";
    for (const [key, value] of Object.entries(query)) {
      queryToSet+=`${key}=${value}&`;
    }

    queryToSet = queryToSet.slice(0, -1);//The last element is removed &


    return this.httpClient.get<Response>(service+queryToSet).pipe(
      tap(responseOK => responseOK),
      catchError(this.GenericCatchError)
    );
  }

  /**
   * This is a private function in TypeScript that catches and throws an error.
   * @param {any} error - The "error" parameter is of type "any", which means it can be any data type.
   * @returns A function that returns an RxJS `Observable` that emits the provided `error` using the
   * `throwError` operator.
   */
  private GenericCatchError(error: any){
    return throwError(() => error);
  }

}

